// Angular
import { Injectable } from '@angular/core';
// RxJS
import { BehaviorSubject, Observable } from 'rxjs';
// Object path
import * as objectPath from 'object-path';
// Services
import { MenuConfigService } from './menu-config.service';
import { Store } from '@ngrx/store';
import { User } from '../../../../core/saas/_models/user.model';
@Injectable()
export class MenuHorizontalService {
  // Public properties
  menuList$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  user$: Observable<User>;
  /**
   * Service constructor
   *
   * @param menuConfigService: MenuConfigService
   */
  constructor(private menuConfigService: MenuConfigService, private store: Store<any>) {
    this.store.subscribe((state) => {
      this.user$ = state.auth.user;
      if (state.auth.user.userType === 'QUANTUM_ADMIN') {
        // Quantum admin login
        this.loadMenu();
      } else if (state.auth.user.userType === 'COMPANY_ADMIN') {
        // Company admin login
        this.loadCompanyAdminMenu();
      } else {
        // Company user login
        this.loadCompanyUserMenu();
      }
    });
    console.log(this.user$);
  }

  /**
   * Load menu list
   */
  loadMenu() {
    // get menu list
    const menuItems: any[] = objectPath.get(this.menuConfigService.getMenus(), 'header.items');
    this.menuList$.next(menuItems);
  }
  loadCompanyAdminMenu() {
    // get menu list
    const menuItems: any[] = objectPath.get(this.menuConfigService.getMenus(), 'header_company_admin.items');
    this.menuList$.next(menuItems);
  }

  loadCompanyUserMenu() {
    // get menu list
    const menuItems: any[] = objectPath.get(this.menuConfigService.getMenus(), 'header_company_user.items');
    this.menuList$.next(menuItems);
  }
}
