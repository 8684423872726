// NGRX
import { createFeatureSelector, createSelector } from '@ngrx/store';
// Lodash
import { each } from 'lodash';
// CRUD
import { QueryResultsModel, HttpExtenstionsModel } from '../../_base/crud';
// State
import { CompanyUsersState } from '../_reducers/companyUser.reducers';
import { CompanyUserModel } from '../_models/companyUser.model';

export const selectcompanyUsersState = createFeatureSelector<CompanyUsersState>('companyUsers');

export const selectcompanyUserById = (companyUserId: number) =>
  createSelector(selectcompanyUsersState, (companyUsersState) => companyUsersState.entities[companyUserId]);

export const selectcompanyUsersPageLoading = createSelector(selectcompanyUsersState, (companyUsersState) => companyUsersState.listLoading);

export const selectcompanyUsersActionLoading = createSelector(
  selectcompanyUsersState,
  (companyUsersState) => companyUsersState.actionsloading
);

export const selectLastCreatedcompanyUserId = createSelector(
  selectcompanyUsersState,
  (companyUsersState) => companyUsersState.lastCreatedcompanyUserId
);

export const selectcompanyUsersShowInitWaitingMessage = createSelector(
  selectcompanyUsersState,
  (companyUsersState) => companyUsersState.showInitWaitingMessage
);

export const selectcompanyUsersInStore = createSelector(selectcompanyUsersState, (companyUsersState) => {
  const items: CompanyUserModel[] = [];
  each(companyUsersState.entities, (element) => {
    items.push(element);
  });
  const httpExtension = new HttpExtenstionsModel();
  const result: CompanyUserModel[] = httpExtension.sortArray(
    items,
    companyUsersState.lastQuery.sortField,
    companyUsersState.lastQuery.sortOrder
  );
  return new QueryResultsModel(result, companyUsersState.totalCount, '');
});
