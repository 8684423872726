// NGRX
import { createFeatureSelector, createSelector } from '@ngrx/store';
// Lodash
import { each } from 'lodash';
// CRUD
import { QueryResultsModel, HttpExtenstionsModel } from '../../_base/crud';
// State
import { ClientsState } from '../_reducers/client.reducers';
import { ClientModel } from '../_models/client.model';

export const selectClientsState = createFeatureSelector<ClientsState>('clients');

export const selectClientById = (clientId: number) => createSelector(
    selectClientsState,
    clientsState => clientsState.entities[clientId]
);

export const selectClientsPageLoading = createSelector(
    selectClientsState,
    clientsState => clientsState.listLoading
);

export const selectClientsActionLoading = createSelector(
    selectClientsState,
    clientsState => clientsState.actionsloading
);

export const selectLastCreatedClientId = createSelector(
    selectClientsState,
    clientsState => clientsState.lastCreatedClientId
);

export const selectClientsShowInitWaitingMessage = createSelector(
    selectClientsState,
    clientsState => clientsState.showInitWaitingMessage
);

export const selectClientsInStore = createSelector(
    selectClientsState,
    clientsState => {
        const items: ClientModel[] = [];
        each(clientsState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: ClientModel[] = httpExtension.sortArray(items, clientsState.lastQuery.sortField, clientsState.lastQuery.sortOrder);
        return new QueryResultsModel(result, clientsState.totalCount, '');
    }
);
