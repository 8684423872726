// Foundation
import {Component, OnInit, Inject, Optional, ChangeDetectorRef} from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material'

// Components
import { DashboardDialogComponent } from './dashboard-dialog/dashboard-dialog.component'

// Service
import { ClientsService } from '../../../../app/core/saas/_services/clients.service.fake'
import { ConfigService } from '../../../../app/config.service'

// Model
import { Products } from '../../../core/saas/_models/products.model'

// NGRX
import { Store } from '@ngrx/store'
import { SubscriptionService } from "../../../core/saas";

@Component({
  selector: 'kt-dashboard-company-admin',
  templateUrl: './dashboard-company-admin.component.html',
  styleUrls: ['dashboard-company-admin.component.scss'],
})
export class DashboardCompanyAdminComponent implements OnInit {
  productList: object;
  constructor(
    @Optional() public dialogRef: MatDialogRef<DashboardDialogComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private clientsService: ClientsService,
    private store: Store<any>,
    private configService: ConfigService,
    private subscriptionService: SubscriptionService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {

    this.subscriptionService.loadAllProducts().subscribe((data => {
      this.productList = data
      this.cdr.detectChanges();
      }) // this will assign data to productList
    )
  }

  checkSubscriptionStatus(productCode: string): void {
    let currentUser = null
    let companyId = null
    this.store.subscribe((state) => {
      currentUser = state.auth.user
      companyId = state.auth.user.companyId
    })

    this.clientsService
      .checkUserSubscription(currentUser.userId, productCode)
      .subscribe((data) => {
        if (data === 'true') {
          // user already subscribed current product
          let url = this.configService.getUrl(
              companyId,
              currentUser.loginId,
              currentUser.password,
              Products[productCode]
          )
          window.open(url)
        } else {
          // user did not subscribed current product
          console.log('false')
          this.showDialogNoSubscription()
        }
      })
  }

  showDialogNoSubscription(): void {
    const dialogRef = this.dialog.open(DashboardDialogComponent, {
      data: { name: 'chen' },
      width: '440px',
    })
    dialogRef.afterClosed().subscribe((res) => {
      if (!res) {
        return
      }
    })
  }
}
