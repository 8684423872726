// Foundation
import { Component, OnInit, ElementRef, ViewChild, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { MatPaginator, MatSort, MatSnackBar, MatDialog } from '@angular/material';

// RxJs
import { debounceTime, distinctUntilChanged, tap, skip, delay, take } from 'rxjs/operators';
import { fromEvent, merge, Subscription, of } from 'rxjs';

// Components
import { UserEditDialogComponent } from '../users-edit/user-edit.dialog.component';

// Service
import { CompanyUserModel, CompanyUsersDataSource, CompanyUsersPageRequested } from '../../../../../../core/saas';

// NGRX
import { Store } from '@ngrx/store';
import { AppState } from '../../../../../../core/reducers';

// Model
import { QueryParamsModel } from '../../../../../../core/_base/crud';

@Component({
  selector: 'kt-users-list',
  templateUrl: './users-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./users-list.component.scss'],
})
export class UsersListComponent implements OnInit, OnDestroy {
  // Table fields
  dataSource: CompanyUsersDataSource;
  displayedColumns = ['company', 'emailAddress', 'fullName', 'role', 'status', 'actions'];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('sort1', { static: true }) sort: MatSort;
  // Filter fields
  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;
  filterStatus = '';
  filterProduct = '';
  // Selection
  selection = new SelectionModel<CompanyUserModel>(true, []);
  usersResult: CompanyUserModel[] = [];
  // Subscriptions
  private subscriptions: Subscription[] = [];

  private store2: Store<any>;
  isQuantumAdmin: boolean;

  constructor(public dialog: MatDialog, public snackBar: MatSnackBar, private store: Store<AppState>, private storeParam: Store<any>) {
    this.store2 = storeParam;
  }

  // Life Cycles
  // ------------------------------------------------------------------------------------

  ngOnInit() {
    this.isQuantumAdmin = false;
    this.store2.subscribe((state) => {
      if (state.auth.user.companyType === 'QUANTUM_ADMIN') {
        this.isQuantumAdmin = true;
      }
    });
    // If the user changes the sort order, reset back to the first page.
    const sortSubscription = this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
    this.subscriptions.push(sortSubscription);

    /* Data load will be triggered in two cases:
    - when a pagination event occurs => this.paginator.page
    - when a sort event occurs => this.sort.sortChange
    **/
    const paginatorSubscriptions = merge(this.sort.sortChange, this.paginator.page)
      .pipe(tap(() => this.loadUsersList()))
      .subscribe();
    this.subscriptions.push(paginatorSubscriptions);

    // Filtration, bind to searchInput
    const searchSubscription = fromEvent(this.searchInput.nativeElement, 'keyup')
      .pipe(
        // tslint:disable-next-line:max-line-length
        debounceTime(50), // The user can type quite quickly in the input box, and that could trigger a lot of server requests. With this operator, we are limiting the amount of server requests emitted to a maximum of one every 150ms
        distinctUntilChanged(), // This operator will eliminate duplicate values
        tap(() => {
          this.paginator.pageIndex = 0;
          this.loadUsersList();
        })
      )
      .subscribe();
    this.subscriptions.push(searchSubscription);

    // Init DataSource
    this.dataSource = new CompanyUsersDataSource(this.store);
    const entitiesSubscription = this.dataSource.entitySubject.pipe(skip(1), distinctUntilChanged()).subscribe((res) => {
      this.usersResult = res;
    });
    this.subscriptions.push(entitiesSubscription);
    // First load
    of(undefined)
      .pipe(take(1), delay(1000))
      .subscribe(() => {
        // Remove this line, just loading imitation
        this.loadUsersList();
      }); // Remove this line, just loading imitation
  }

  ngOnDestroy() {
    this.subscriptions.forEach((el) => el.unsubscribe());
  }

  loadUsersList() {
    this.selection.clear();
    const queryParams = new QueryParamsModel(
      this.filterConfiguration(),
      this.sort.direction,
      this.sort.active,
      this.paginator.pageIndex,
      this.paginator.pageSize
    );
    // Call request from server
    // this.store.dispatch(new ClientsPageRequested({ page: queryParams }));
    this.store.dispatch(new CompanyUsersPageRequested({ page: queryParams }));
    this.selection.clear();
  }

  filterConfiguration(): any {
    const filter: any = {};
    const searchText: string = this.searchInput.nativeElement.value;

    // if (this.filterStatus && this.filterStatus.length > 0) {
    //   filter.status = +this.filterStatus;
    // }

    // if (this.filterProduct && this.filterProduct.length > 0) {
    //   filter.product = this.filterProduct;
    // }

    filter.companyName = searchText;
    filter.loginId = searchText;
    return filter;
  }

  addUser() {
    const newUser = new CompanyUserModel();
    newUser.clear(); // Set all defaults fields

    const dialogRef = this.dialog.open(UserEditDialogComponent, { data: { user: newUser, isAddOrEdit: 'add' } });

    dialogRef.afterClosed().subscribe(() => {
      this.loadUsersList();
    });
  }

  getItemCssClassByStatus(status: string): string {
    switch (status) {
      case 'ACTIVE':
        return 'success';
      case 'PENDING':
        return 'metal';
    }
    return '';
  }

  getItemStatusString(status: number = 0): string {
    switch (status) {
      case 0:
        return 'Suspended';
      case 1:
        return 'Active';
      case 2:
        return 'Pending';
    }
    return '';
  }

  getFullWord(input: string) {
    input = input.toLowerCase();
    let res = '';
    for (let i = 0; i < input.length; i++) {
      if (i === 0 || (i - 1 >= 0 && input.charAt(i - 1) === '_')) {
        res += input.charAt(i).toUpperCase();
      } else {
        res += input.charAt(i);
      }
    }
    res = res.replace('_', ' ');
    return res;
  }

  editCompanyUser(companyUser: CompanyUserModel) {
    const dialogRef = this.dialog.open(UserEditDialogComponent, { data: { user: companyUser, isAddOrEdit: 'edit' } });
    dialogRef.afterClosed().subscribe((res) => {
      this.loadUsersList();
    });
  }
}
