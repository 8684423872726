// Foundation
import { Component, OnInit, Inject, ChangeDetectionStrategy, ViewEncapsulation, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';

// RxJs
import { Subscription, of, Observable, Subject } from 'rxjs';
import { delay, map, tap } from 'rxjs/operators';

// Components
import { ClientConfirmationDialogComponent } from '../client-confirmation-dialog/client-confirmation-dialog.component';

// Service
import { TypesUtilsService } from '../../../../../../core/_base/crud';
import { ClientModel, ClientUpdated, ClientOnServerCreated, selectClientsActionLoading } from '../../../../../../core/saas';
import { ClientsService } from '../../../../../../../app/core/saas/_services/clients.service.fake';

// NGRX
import { Update } from '@ngrx/entity';
import { Store, select } from '@ngrx/store';
import { AppState } from '../../../../../../core/reducers';

import { CompanyIdValidator } from './company-id.validator';

@Component({
  selector: 'kt-client-edit-dialog',
  templateUrl: './client-edit.dialog.component.html',
  styleUrls: ['./client-edit.dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class ClientEditDialogComponent implements OnInit, OnDestroy {
  // Public properties
  isCompanyIdDuplicate = false;
  showProductChangingSpinner = false;
  currentProduct: string;
  isAddClient: boolean;
  selected: boolean;
  client: ClientModel;
  clientEditForm: FormGroup;
  hasFormErrors = false;
  viewLoading = false;
  currentLanguage: string;
  isProductEmpty = false;
  // Private properties
  private componentSubscriptions: Subscription;
  private clientServiceSubscription: Subscription;

  // For Angular Chips
  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  filteredProducts: Observable<string[]>;
  displayedProducts: string[];
  @ViewChild('productInput', { static: false }) productInput: ElementRef<HTMLInputElement>;
  productDisabled = false;
  @ViewChild('auto', { static: false }) matAutocomplete: MatAutocomplete;

  /**
   * Component constructor
   *
   * @param dialogRef: MatDialogRef<ClientEditDialogComponent>
   * @param data: any
   * @param fb: FormBuilder
   * @param store: Store<AppState>
   * @param typesUtilsService: TypesUtilsService
   * @param clientsService: client service
   */
  constructor(
    public dialogRef: MatDialogRef<ClientEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private store: Store<AppState>,
    private typesUtilsService: TypesUtilsService,
    private dialog: MatDialog,
    private clientsService: ClientsService
  ) {
    // this.filteredProducts = this.productCtrl.valueChanges.pipe(
    // 	startWith(null),
    // 	map((product: string | null) => product ? this._filter(product) : this.allProducts.slice()));
  }

  // Life Cycles
  // ------------------------------------------------------------------------------------

  ngOnInit(): void {
    this.store.pipe(select(selectClientsActionLoading)).subscribe((res) => (this.viewLoading = res));
    this.client = this.data.client;
    this.createForm();
    if (this.client.userStatus === 'ACTIVE') {
      Object.keys(this.clientEditForm.controls).forEach((name) => {
        this.clientEditForm.controls[name].disable();
      });
    }
  }

  ngOnDestroy(): void {
    if (this.clientServiceSubscription) {
      this.clientServiceSubscription.unsubscribe();
    }
    if (this.componentSubscriptions) {
      this.componentSubscriptions.unsubscribe();
    }
  }

  createForm() {
    this.clientEditForm = this.fb.group({
      companyName: [this.client.companyName, Validators.required],
      companyId: [this.client.companyId, Validators.compose([Validators.required, CompanyIdValidator.cannotContainSpace])],
      userFirstName: [this.client.userFirstName, Validators.required],
      userLastName: [this.client.userLastName, Validators.required],
      loginId: [this.client.loginId, Validators.compose([Validators.required, Validators.email])],
      userPhone: [this.client.userPhone, Validators.required],
      userJobTitle: [this.client.userJobTitle],
      userLanguage: [this.client.userLanguage, Validators.required],
    });
  }

  getTitle(): string {
    if (this.client.id > 0) {
      return `Edit client '${this.client.companyName}'`;
    }

    return 'New client';
  }

  isControlInvalid(controlName: string): boolean {
    const control = this.clientEditForm.controls[controlName];
    const result = control.invalid && control.touched;
    return result;
  }

  prepareClient(): ClientModel {
    const controls = this.clientEditForm.controls;
    const client = new ClientModel();
    client.id = this.client.id;
    client.clientId = this.client.clientId;
    client.userId = this.client.userId;
    client.userEmailAddress = controls.loginId.value;
    client.loginId = controls.loginId.value;
    client.companyName = controls.companyName.value;
    client.companyId = controls.companyId.value;
    client.companyType = 'GENERAL_CLIENT';
    client.userType = 'COMPANY_ADMIN';
    client.userStatus = 'PENDING';
    client.userLanguage = controls.userLanguage.value;
    client.userFirstName = controls.userFirstName.value;
    client.userLastName = controls.userLastName.value;
    client.userPhone = controls.userPhone.value;
    client.userJobTitle = controls.userJobTitle.value;
    return client;
  }

  getSaaSModuleIdByProductName(name: string) {
    if (name === 'Safety Observation') {
      return 1;
    } else if (name === 'Chemical Management') {
      return 2;
    } else if (name === 'COVID Check') {
      return 3;
    } else if (name === 'Regulation') {
      return 4;
    } else {
      return 0;
    }
  }

  // View Events
  // ------------------------------------------------------------------------------------

  createOrUpdateClient(client: ClientModel) {
    this.clientsService
      .isCompanyIdDuplicate(client.companyId, client.clientId)
      .pipe(
        map((data) => {
          //debugger;
          if (data === 'true') {
            // duplicate company id
            this.isCompanyIdDuplicate = true;
          } else {
            this.store.dispatch(new ClientOnServerCreated({ client }));
            this.dialogRef.close({ client, isEdit: false });
            this.ngOnInit();
          }
        })
      )
      .subscribe();
  }

  onAlertClose($event) {
    this.hasFormErrors = false;
  }

  getItemStatusString(status: number = 0): string {
    switch (status) {
      case 0:
        return 'Suspended';
      case 1:
        return 'Active';
      case 2:
        return 'Pending';
    }
    return '';
  }
  getItemLanguageString(status: number = 0): string {
    switch (status) {
      case 0:
        return 'English';
      case 1:
        return 'Simplified Chinese';
    }
    return '';
  }

  getItemProductString(status: number = 0): string {
    switch (status) {
      case 0:
        return 'Safety Observation';
      case 1:
        return 'Safety Observation2';
    }
    return '';
  }
  isTrial(): boolean {
    const baseCharIndex = 8;
    if (
      this.client.companyId.charAt(baseCharIndex) === '-' &&
      this.client.companyId.charAt(baseCharIndex + 5) === '-' &&
      this.client.companyId.charAt(baseCharIndex + 10) === '-' &&
      this.client.companyId.charAt(baseCharIndex + 15) === '-'
    ) {
      return true;
    }
    return false;
  }

  isProductExist(): boolean {
    if (this.clientEditForm.controls.companyName.value === '') {
      return false;
    }
    return true;
  }

  activateClient(editedClient: ClientModel) {
    const dialogRef = this.dialog.open(ClientConfirmationDialogComponent, {
      data: { editedClient },
      width: '440px',
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (!res) {
        return;
      }

      of(undefined)
        .pipe()
        .subscribe(() => this.dialogRef.close({ editedClient, isEdit: true }));
    });
  }

  onDeactivate() {
    this.showProductChangingSpinner = true;
    const editedClient = this.prepareClient();
    this.clientServiceSubscription = this.clientsService
      .deactivateClient(editedClient)
      .pipe(
        tap((str) => {
          if (str === 'SUCCESS') {
            this.showProductChangingSpinner = false;
            of(undefined)
              .pipe()
              .subscribe(() => this.dialogRef.close({ editedClient, isEdit: true }));
          }
        })
      )
      .subscribe();
  }

  onSave() {
    this.showProductChangingSpinner = true;
    const client: ClientModel = this.prepareClient();
    const controls = this.clientEditForm.controls;
    if (this.clientEditForm.invalid) {
      Object.keys(controls).forEach((controlName) => controls[controlName].markAsTouched());
      this.showProductChangingSpinner = false;
      return;
    }

    this.createOrUpdateClient(client);
    this.showProductChangingSpinner = false;
  }
}
