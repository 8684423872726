// Foundation
import { Component, OnInit, Inject, ChangeDetectionStrategy, ViewEncapsulation, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';

// RxJs
import { Subscription, of } from 'rxjs';

// Components
import { UserConfirmationDialogComponent } from '../users-comfirmation-dialog/user-confirmation-dialog.component';

// Service
import {
  CompanyUserModel,
  selectcompanyUsersActionLoading,
  CompanyUserOnServerCreated,
  CompanyUsersService,
} from '../../../../../../core/saas';

// NGRX
import { Store, select } from '@ngrx/store';
import { AppState } from '../../../../../../core/reducers';
import { Logout } from '../../../../../../core/auth';
import { ConfirmationDialogComponent } from '../../shared/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'kt-user-edit-dialog',
  templateUrl: './user-edit.dialog.component.html',
  styleUrls: ['./user-edit.dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class UserEditDialogComponent implements OnInit, OnDestroy {
  currentProduct: number;
  selected: boolean;
  user: CompanyUserModel;
  userForm: FormGroup;
  hasFormErrors = false;
  viewLoading = false;
  isAddUser: boolean;
  clientId: number;
  companyId: string;
  adminUserId: number;
  private store2: Store<any>;
  private componentSubscriptions: Subscription;
  companyUsersService: CompanyUsersService;
  showSpinner: boolean;
  currentLoginUserType: string;

  constructor(
    public dialogRef: MatDialogRef<UserEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private store: Store<AppState>,
    private dialog: MatDialog,
    private storeParam: Store<any>,
    companyUsersService: CompanyUsersService
  ) {
    this.store2 = storeParam;
    this.companyUsersService = companyUsersService;
  }

  // Life Cycles
  // ------------------------------------------------------------------------------------

  ngOnInit(): void {
    this.store.pipe(select(selectcompanyUsersActionLoading)).subscribe((res) => (this.viewLoading = res));

    this.user = this.data.user;
    console.log('this.user', this.user);

    this.createForm();

    this.store2.subscribe((state) => {
      // this.clientId = state.auth.user.clientId;
      // this.companyId = state.auth.user.companyId;
      this.adminUserId = state.auth.user.userId;
      this.currentLoginUserType = state.auth.user.userType;
    });

    // const date = new Date();
    // const time = date.getHours() + ':' + date.getMinutes();
    // console.log('time :' + time + ', edit page this.data.isAddOrEdit = ' + this.data.isAddOrEdit);
    if (this.data.isAddOrEdit === 'add') {
      this.isAddUser = true;
      this.userForm.controls.userType.setValue('GENERAL_USER');
    } else {
      this.isAddUser = false;
    }
    this.userForm.controls.userType.disable();
    if (this.user.userStatus !== 'PENDING') {
      Object.keys(this.userForm.controls).forEach((controlName) => {
        this.userForm.controls[controlName].disable();
      });
    }
    if (this.currentLoginUserType === 'COMPANY_ADMIN' && this.user.userType === 'GENERAL_USER' && this.user.userStatus === 'ACTIVE') {
      this.userForm.controls.userType.enable();
    }
  }

  ngOnDestroy(): void {
    if (this.componentSubscriptions) {
      this.componentSubscriptions.unsubscribe();
    }
  }

  createForm(): void {
    console.log(this.user.userType);
    this.userForm = this.fb.group({
      loginId: [this.user.loginId, Validators.compose([Validators.required, Validators.email])],
      firstName: [this.user.userFirstName, Validators.required],
      lastName: [this.user.userLastName, Validators.required],
      phone: [this.user.userPhone],
      jobTitle: [this.user.userJobTitle],
      userLanguage: [this.user.userLanguage, Validators.required],
      userType: [{ value: this.user.userType, disabled: true }, Validators.required],
    });
  }

  // getItemStatusString(status: number = 0): string {
  //   switch (status) {
  //     case 0:
  //       return '';
  //     case 1:
  //       return 'Active';
  //     case 2:
  //       return 'Pending';
  //   }
  //   return '';
  // }

  prepareUser(): CompanyUserModel {
    const controls = this.userForm.controls;
    let companyUser = new CompanyUserModel();

    companyUser = JSON.parse(JSON.stringify(this.user));
    companyUser.clientId = this.user.clientId;
    companyUser.companyId = this.user.companyId;
    companyUser.userEmailAddress = controls.loginId.value;
    companyUser.loginId = controls.loginId.value;
    companyUser.userType = controls.userType.value;
    companyUser.userFirstName = controls.firstName.value;
    companyUser.userLastName = controls.lastName.value;
    companyUser.userPhone = controls.phone.value;
    companyUser.userJobTitle = controls.jobTitle.value;
    companyUser.userLanguage = controls.userLanguage.value;
    companyUser.userStatus = 'PENDING';
    return companyUser;
  }

  // isActive(): boolean {
  //   return this.user.status === 1;
  // }
  // isPending(): boolean {
  //   return this.user.status === 2;
  // }

  // View Events
  // ------------------------------------------------------------------------------------

  onSave(): void {
    this.hasFormErrors = false;
    const controls = this.userForm.controls;

    if (this.userForm.invalid) {
      Object.keys(controls).forEach((controlName) => controls[controlName].markAsTouched());
      return;
    }

    const companyUser = this.prepareUser();
    console.log('companyUser', companyUser);
    // this.store.dispatch(new CompanyUserOnServerCreated({ companyUser }));

    const dialogRef = this.dialog.open(UserConfirmationDialogComponent, {
      data: { companyUser, dialogContent: 'Are you sure to send invitation link to this user?' },
      width: '440px',
    });

    dialogRef.afterClosed().subscribe((data) => {
      console.log('data: ' + data);
      if (data === 'yes') {
        this.companyUsersService.createOrUpdateCompanyUser(companyUser).subscribe((data) => {
          if (data === 'SUCCESS') {
            this.dialogRef.close();
          }
        });
      }
    });

    // this.dialogRef.close({ companyUser, isEdit: true });
  }

  onChangeRole(): void {
    const companyUser = this.prepareUser();
    if (companyUser.userType === this.user.userType) {
      this.dialogRef.close();
      return;
    }
    //debugger;
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: { contentTranslationCode: 'COMPANY_USER.ALERT.CHANGE_ROLE_MESSAGE' },
      width: '440px',
    });

    // const dialogRef = this.dialog.open(UserConfirmationDialogComponent, {
    //   data: {
    //     companyUser,
    //     dialogContent: 'Are you sure to change this user to admin? By doing so, you may be logged out and become a general user',
    //   },
    //   width: '440px',
    // });

    dialogRef.afterClosed().subscribe((data) => {
      if (data === 'yes') {
        this.showSpinner = true;
        this.companyUsersService.exchangeRole(this.adminUserId, companyUser.userId).subscribe((data) => {
          if (data === 'SUCCESS') {
            this.dialogRef.close();
            this.showSpinner = false;
            this.store.dispatch(new Logout());
          }
        });
      }
    });
  }
}
