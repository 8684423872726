import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatSnackBar, MatSnackBarConfig } from '@angular/material';
import moment from 'moment';
import { SubscriptionService, SubscriptionModel } from '../../../../../../core/saas';
import { TranslateService } from '@ngx-translate/core';
import { ConfigService, SNACKBAR } from '../../../../../../../app/config.service';
import { SimpleDialogComponent } from '../../shared/simple-dialog/simple-dialog.component';

@Component({
  selector: 'kt-subscriptions-edit',
  templateUrl: './subscriptions-edit.dialog.component.html',
  styleUrls: ['./subscriptions-edit.dialog.component.scss'],
})
export class SubscriptionsEditDialogComponent implements OnInit {
  subscriptionDuplicate: boolean;
  subscriptionForm: FormGroup;
  clientList: object;
  productList: object;
  startDate: any;
  minStartDate: Date = new Date();
  minEndDate: Date = new Date();
  // period: string;
  subscription: SubscriptionModel;
  roleName = '';
  isRenewSwitchOn = false;
  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<SubscriptionsEditDialogComponent>,
    private subscriptionService: SubscriptionService,
    private translate: TranslateService,
    private configService: ConfigService,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.subscription = this.data;
    this.createForm();

    const afterSub = new Date(moment(new Date(this.subscription.subscriptionEndTime))
    .add(1, 'd').format('MM/DD/YYYY'));
    const today = new Date();
    this.minStartDate = today > afterSub ? today : afterSub;
    this.minEndDate = new Date(moment(this.minStartDate).add(1, 'd').format('MM/DD/YYYY'));
    this.subscriptionService.loadAllGeneralClients().subscribe((data) => {
      this.clientList = data;
    });
    this.subscriptionService.loadAllProducts().subscribe((data) => {
      this.productList = data;
      // tslint:disable-next-line: forin
      for (const i in this.productList) {
        this.productList[i].productName = this.configService.translateProductName(this.productList[i].productCode);
      }
    });

    this.getSubscriptionType(this.subscription.saasModuleId, this.subscription.roleCode);
    const dateTime = this.formatDate(new Date());
    console.log('dateTime', dateTime);
    console.log('newDate', new Date());
    // this.showStartTime();
    this.subscriptionDuplicate = false;
  }

  showStartTime() {
    // if (this.subscription === null) {
    //   this.subscriptionForm.controls.subscriptionStartTime.setValue(new Date());
    //   // this.subscriptionForm.controls.subscriptionStartTime.disable();
    // } else
    if (this.subscription.subscriptionStatus === 'PENDING') {
      this.subscriptionForm.controls.subscriptionStartTime.setValue(new Date(this.subscription.subscriptionStartTime));
    } else {
      this.subscriptionForm.controls.subscriptionStartTime.setValue(new Date(this.subscription.subscriptionStartTime));
      // this.subscriptionForm.controls.subscriptionStartTime.disable();
    }
    // this.calculateEndDate();
  }

  // enableDatePicker(): boolean {
  //   if (this.subscription !== null && this.subscription.subscriptionStatus === 'PENDING') {
  //     return true;
  //   }
  //   return false;
  // }

  toggleDatePicker() {
    this.isRenewSwitchOn = !this.isRenewSwitchOn;
    if (this.isRenewSwitchOn) {
      this.subscriptionForm.controls.subscriptionStartTime.enable();
      this.subscriptionForm.controls.subscriptionEndTime.enable();
    } else {
      this.subscriptionForm.controls.subscriptionStartTime.disable();
      this.subscriptionForm.controls.subscriptionEndTime.disable();
    }
  }

  // showConfirmButton(): boolean {
  //   if (this.subscription === null || this.subscription.subscriptionStatus === 'PENDING') {
  //     return true;
  //   }
  //   return false;
  // }

  createForm() {
    const subscription = this.subscription;
    // if (subscription === null) {
    //   this.subscriptionForm = this.fb.group({
    //     companyName: ['', Validators.required],
    //     productCode: ['', Validators.required],
    //     subscriptionStartTime: ['', Validators.required],
    //     period: ['', Validators.required],
    //     // subscriptionEndTime: [{ value: '', disabled: true }, Validators.required],
    //     subscriptionEndTime: ['', Validators.required],
    //   });
    // } else {
    const start = this.formatDate(subscription.subscriptionStartTime);
    const end = this.formatDate(subscription.subscriptionEndTime);
    // const period = this.calculatePeriod(start, end);
    // let peroidString = '';
    // if (period === 14) {
    //   peroidString = '14DAY';
    // } else if (period >= 28 && period <= 31) {
    //   peroidString = '1MONTH';
    // } else {
    //   peroidString = '6MONTH';
    // }
    // Trial account, to activate.
    this.subscriptionForm = this.fb.group({
      companyName: [subscription.clientId, Validators.required],
      productCode: [subscription.saasModuleId, Validators.required],
      subscriptionStartTime: [{ value: '', disabled: !this.isRenewSwitchOn}, Validators.required],
      // period: [peroidString, Validators.required],
      // subscriptionEndTime: [{ value: end, disabled: true }, Validators.required],
      subscriptionEndTime: [{ value: '', disabled: !this.isRenewSwitchOn}, Validators.required],
    });
    // }
  }

  prepareData() {
    let isSubscribeNow = 'false';
    if (this.isToday(new Date(this.subscriptionForm.controls.subscriptionStartTime.value))) {
      isSubscribeNow = 'true';
    }
    const data = {
      id: this.subscription.subscriptionId,
      clientId: this.subscription.clientId,
      subscriptionStartTime: this.formatDate(this.subscriptionForm.controls.subscriptionStartTime.value),
      subscriptionEndTime: this.formatDate(this.subscriptionForm.controls.subscriptionEndTime.value),
      productId: this.subscription.saasModuleId,
      isSubscribeNow,
      roleCode: this.subscription.roleCode
    };
    return data;
  }

  formatDate(date) {
    const momentDate = moment(new Date(date));
    const formattedDate = momentDate.format('MM/DD/YYYY');
    return formattedDate;
  }

  isToday(date): boolean {
    const today = new Date();
    return date.getDate() === today.getDate() && date.getMonth() === today.getMonth() && date.getFullYear() === today.getFullYear();
  }

  onSave() {
    this.subscriptionDuplicate = false;
    const data = this.prepareData();
    //debugger;
    if (this.subscriptionForm.invalid) {
      Object.keys(this.subscriptionForm.controls).forEach((controlName) => this.subscriptionForm.controls[controlName].markAsTouched());
      return;
    }

    // activate existing subscription
    this.subscriptionService.isSubscriptionValid(data).subscribe((message) => {
      if (message === 'true') {
        this.subscriptionService.activateSubscription(data).subscribe();
        setTimeout(() => {
          this.dialogRef.close();
        }, 500);
      } else {
        this.subscriptionDuplicate = true;
      }
    });
  }

  cancelSubscription() {
    const cancelDialogRef = this.dialog.open(SimpleDialogComponent, {
      data: {
        titleTranslationCode: 'SUBSCRIPTION.UNSUBSCRIBE.TITLE',
        messageTranslationCode: 'SUBSCRIPTION.UNSUBSCRIBE.DESCRIPTION',
        actionTextTranslationCode: 'SUBSCRIPTION.UNSUBSCRIBE.CONFIRM_ACTION',
        cancelTextTranslationCode: 'SUBSCRIPTION.UNSUBSCRIBE.CANCEL_ACTION',
        actionClass: 'btn-danger',
        reverseActionButtonOrder: true
      },
      width: '350px'
    });

    cancelDialogRef.afterClosed().subscribe((response) => {
      if (response === 'yes') {
        this.subscriptionService.cancelSubscription({
          id: this.subscription.id,
          clientId: this.subscription.clientId,
          productId: this.subscription.saasModuleId
        }).subscribe(res => {
          this.dialogRef.close();
          if (res === 'SUCCESS') {
            this.showSnackBarAfterUnsubscribe(true);
          } else {
            this.showSnackBarAfterUnsubscribe(false);
          }
        });
      }
    });
  }

  showSnackBarAfterUnsubscribe(isSuccess: boolean) {
    if (isSuccess) {
      const message = this.configService.translateSnackBarMessage(SNACKBAR.CANCEL_SUBSCRIPTION_SUCCESS);
      const action = this.configService.translateSnackbarAction(SNACKBAR.ACTION_CLOSE);

      const config: MatSnackBarConfig = { duration: 3000, panelClass: ['saas-snackbar-style-success'] };
      this.snackBar.open(message, action, config);
    } else if (!isSuccess) {
      const message = this.configService.translateSnackBarMessage(SNACKBAR.CANCEL_SUBSCRIPTION_FAILED);
      const action = this.configService.translateSnackbarAction(SNACKBAR.ACTION_CLOSE);

      const config: MatSnackBarConfig = { duration: 3000, panelClass: ['saas-snackbar-style-fail'] };
      this.snackBar.open(message, action, config);
    }
  }

  calculateMinEndDate() {
    const date = this.subscriptionForm.controls.subscriptionStartTime.value;
    if (!date) {
      return;
    }

    const momentDate = moment(new Date(date));
    this.minEndDate = new Date(momentDate.add(1, 'd').format('MM/DD/YYYY'));
  }

  getSubscriptionType(productCode: number, roleCode: string) {
    if (productCode && roleCode) {
      this.subscriptionService.loadAllSubscriptionTypes(productCode).subscribe(res => {
        for (var ele of res.data) {
          if (ele.roleCode === this.subscription.roleCode) {
            this.roleName = ele.roleName;
          }
        }
      });
    }
  }
  // calculateEndDate() {
  //   const period = this.subscriptionForm.controls.period.value;
  //   const date = this.subscriptionForm.controls.subscriptionStartTime.value;
  //   if (period === '' || date === '' || period === null || date === null) {
  //     this.subscriptionForm.controls.subscriptionEndTime.setValue('');
  //     return;
  //   }
  //   const momentDate = moment(new Date(date));
  //   let futureDate;
  //   switch (period) {
  //     case '14DAY':
  //       futureDate = momentDate.add(14, 'd').format('MM/DD/YYYY');
  //       break;
  //     case '6MONTH':
  //       futureDate = momentDate.add(6, 'M').format('MM/DD/YYYY');
  //       break;
  //     case '1MONTH':
  //       futureDate = momentDate.add(1, 'M').format('MM/DD/YYYY');
  //       break;
  //   }
  //   this.subscriptionForm.controls.subscriptionEndTime.setValue(futureDate);
  // }

  // calculatePeriod(startDate: string, endDate: string): number {
  //   const firstDate = moment(startDate);
  //   const secondDate = moment(endDate);
  //   return Math.abs(firstDate.diff(secondDate, 'days'));
  // }

  get productName(): string {
    let res = '';
    for (const i in this.productList) {
      if (this.productList[i].id === this.subscription.saasModuleId) {
        res = this.productList[i].productName;
        break;
      }
    }
    return res;
  }
}
