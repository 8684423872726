// NGRX
import { Action } from '@ngrx/store';
// CRUD
import { QueryParamsModel } from '../../_base/crud';
// Models
import { CompanyUserModel } from '../_models/companyUser.model';

export enum CompanyUserActionTypes {
  CompanyUserOnServerCreated = '[Edit companyUser Dialog] CompanyUser On Server Created',
  CompanyUsersPageLoaded = '[companyUsers API] CompanyUsers Page Loaded',
  CompanyUsersPageToggleLoading = '[companyUsers] CompanyUsers Page Toggle Loading',
  CompanyUserActionToggleLoading = '[companyUsers] CompanyUsers Action Toggle Loading',
  CompanyUsersPageRequested = '[companyUsers] CompanyUsers Page Requested',
  CompanyUserCreated = '[companyUsers] CompanyUser Created',
}

export class CompanyUserOnServerCreated implements Action {
  readonly type = CompanyUserActionTypes.CompanyUserOnServerCreated;
  constructor(public payload: { companyUser: CompanyUserModel }) {}
}

export class CompanyUsersPageRequested implements Action {
  readonly type = CompanyUserActionTypes.CompanyUsersPageRequested;
  constructor(public payload: { page: QueryParamsModel }) {}
}

export class CompanyUsersPageLoaded implements Action {
  readonly type = CompanyUserActionTypes.CompanyUsersPageLoaded;
  constructor(public payload: { companyUsers: CompanyUserModel[]; totalCount: number; page: QueryParamsModel }) {}
}

export class CompanyUsersPageToggleLoading implements Action {
  readonly type = CompanyUserActionTypes.CompanyUsersPageToggleLoading;
  constructor(public payload: { isLoading: boolean }) {}
}

export class CompanyUserActionToggleLoading implements Action {
  readonly type = CompanyUserActionTypes.CompanyUserActionToggleLoading;
  constructor(public payload: { isLoading: boolean }) {}
}
export class CompanyUserCreated implements Action {
  readonly type = CompanyUserActionTypes.CompanyUserCreated;
  constructor(public payload: { isLoading: boolean }) {}
}
export type CompanyUserActions =
  | CompanyUserOnServerCreated
  | CompanyUserCreated
  | CompanyUsersPageRequested
  | CompanyUsersPageLoaded
  | CompanyUsersPageToggleLoading
  | CompanyUserActionToggleLoading;
