// China
export const locale = {
  lang: 'ch',
  data: {
    PAGINATOR: {
      ITEMS_PER_PAGE: '每页显示条数',
      OF: '总条数',
    },
    TRANSLATOR: {
      SELECT: '选择你的语言',
    },
    BUTTON: {
      CANCEL: '取消',
      CONFIRM: '确认',
      SAVE: '保存',
      ACTIVATE: '激活',
      DEACTIVATE: '注销',
    },
    LANGUAGE: {
      ENGLISH: '英语',
      SIMPLIFIED_CHINESE: '简体中文',
      TRADITIONAL_CHINESE: '繁体中文',
    },
    PRODUCT: {
      Chemical_Management: '化学品管理',
      SAFETY_OBSERVATION: '安全观察',
      COVID_CHECK: 'COVID Check',
      REGULATION: '法规鉴别',
      TRAINING: '培训与证照',
      RISK: '风险评估',
      INCIDENT: '事故管理',
    },
    ERROR: {
      FORM_FIELD_INVALID_ALERT: '请检查各项内容合法性',
      IS_REQUIRED: '为空或格式不正确',
      COMPANY_ID_DUPLICATE: '公司ID已存在',
    },
    SEARCH: {
      ALL_SEARCH: '全局搜索',
      ALL: '所有',
      SUSPEND: '暂停',
      ACTIVE: '已激活',
      PENDING: '待激活',
      INACTIVE: '已注销',
      SEARCH: '搜索',
      FILTER_BY_STATUS: '通过状态筛选',
      FILTER_BY_TYPE: '通过类别筛选',
      FILTER_BY_PRODUCT: '通过产品筛选',
      SEARCH_IN_ALL_FIELD: '在所有内容中筛选',
    },
    MENU: {
      NEW: '新增',
      ACTIONS: '功能',
      CREATE_POST: '创建新帖子',
      PAGES: '页面',
      FEATURES: '功能',
      APPS: '应用',
      DASHBOARD: '仪表板',
      LAYOUTBUILDER: '界面',
      CLIENTS: '客户管理',
      USERS: '用户管理',
      SUBSCRIPTIONS: '订阅',
      ALL_APPS: '全部应用',
    },
    AUTH: {
      GENERAL: {
        OR: '或者',
        SUBMIT_BUTTON: '提交',
        NO_ACCOUNT: '没有账号？',
        SIGNUP_BUTTON: '注册',
        FORGOT_BUTTON: '忘记密码',
        BACK_BUTTON: '退回',
        PRIVACY: '隐私',
        LEGAL: '法律',
        CONTACT: '联系',
      },
      LOGIN: {
        TITLE: '登录帐号',
        BUTTON: '登录',
      },
      FORGOT: {
        TITLE: '忘记密码?',
        DESC: '请输入您的电子邮箱地址重置密码。',
        SUCCESS: '您的密码已经被成功保存，正在载入您的产品...',
      },
      REGISTER: {
        TITLE: '注册',
        DESC: 'Enter your details to create your account',
        SUCCESS: 'Your account has been successfully registered.',
      },
      INPUT: {
        EMAIL: '电子邮箱地址',
        FULLNAME: '全名',
        PASSWORD: '密码',
        CONFIRM_PASSWORD: '再一次输入密码',
        USERNAME: '用戶名',
        IAGREE: '我已阅读并同意',
        TERMS: '服务协议',
        AND: '和',
        CONDITIONS: '隐私声明',
        NOACCOUNT: '还没有帐号?',
      },
      RESET: {
        TITLE: '设置密码',
        PASSWORD: '密码',
        CONFIRM_PASSWORD: '再一次输入密码',
        FIRST_NAME: 'First Name',
        LAST_NAME: 'Last Name',
      },
      VALIDATION: {
        INVALID: '{{name}} is not valid',
        REQUIRED: '{{name}} is required',
        MIN_LENGTH: '{{name}} minimum length is {{min}}',
        AGREEMENT_REQUIRED: 'Accepting terms & conditions are required',
        NOT_FOUND: '{{name}}没有找到',
        INVALID_LOGIN: '您的登录信息不正确，请检查!',
        INVALID_FIELD: 'Field is not valid',

        REQUIRED_FIELD: '不可为空',
        MIN_LENGTH_FIELD: '最短密码长度:',
        PASSWORD_DOESNOT_MATCH: '两次输入的密码不匹配',
      },
    },
    CLIENT: {
      TITLE: {
        NEW_CLIENT: '新建客户',
        CLIENTS: '客户管理',
      },
      PROFILE: {
        SIGNOUT: '登出',
      },
      TABLE: {
        COMPANY_NAME: '公司名称',
        COMPANY_ID: '公司ID',
        LANGUAGE: '通知语言',
        ADMIN_USER: '管理员邮箱账号',
        PRODUCT: '产品',
        STATUS: '状态',
        ACTIONS: '操作',
        COMPANY_ADMIN: 'Admin',
        GENERAL_USER: 'General User',
        FIRST_NAME: '名',
        LAST_NAME: '姓',
        PHONE: '电话',
        JOB_TITLE: '职位',
      },
      BUTTON: {
        CANCEL: '取消',
        SAVE: '保存',
        ACTIVATE: '激活',
        DEACTIVATE: '注销',
      },

      CONFIRMATION: {
        ACTIVATE: '您确定要激活此公司数据库吗?',
      },
    },
    COMPANY_USER: {
      DASHBOARD: {
        CHOOSE_PRODUCT: '选择你的产品',
      },
      TABLE: {
        COMPANY: '公司名称',
        LOGIN_EMAIL: '登录邮箱',
        ROLE: '角色',
        STATUS: '状态',
        ACTIONS: '操作',
        LANGUAGE: '通知语言',
        FIRST_NAME: '名字',
        LAST_NAME: '姓氏',
        FULL_NAME: '全名',
        PHONE: '电话',
        JOB_TITLE: '职位',
      },
      TITLE: {
        NEW_USER: '新建用户',
        USER_MANAGEMENT: '用户管理',
      },
      BUTTON: {
        SAVE: 'Save',
      },
      ALERT: {
        CHANGE_ROLE: 'SUCCESS, logging out',
        CHANGE_ROLE_MESSAGE:
          'Only one user will be company admin. Are you sure to continue?',
      },
    },
    SUBSCRIPTION: {
      SUBSCRIPTION: '订阅',
      TABLE: {
        CLIENT: '客户',
        PRODUCT: '产品',
        START_DATE: '开始时间',
        PERIOD: '时长',
        DAYS14: '14天',
        MONTH1: '1个月',
        MONTH6: '6个月',
        DAYS: '1天',
        END_DATE: '结束时间',
        STATUS: '状态',
        ADD_SUBSCRIPTION: '新增订阅',
        CLIENT_INFO: '客户资讯',
        SUBSCRIPTION_TYPE: '订阅类型',
        SUBSCRIPTION_INFO: '订阅资讯',
        SUBSCRIPTION_HISTORY: '历史订阅',
        RENEW_SUBSCRIPTION: '续订',
        CANCEL_SUBSCRIPTION: '取消订阅',
        DATE: '时间',
      },
      UNSUBSCRIBE: {
        TITLE: '取消订阅',
        DESCRIPTION: '你确定要取消订阅吗?',
        CONFIRM_ACTION: '取消订阅',
        CANCEL_ACTION: '不要取消',
        SUCCESS_MESSAGE: '取消订阅成功',
        FAIL_MESSAGE: '取消订阅失败',
      },
      SYNC: {
        SUCCESS_MESSAGE: '同步成功 !',
        FAIL_MESSAGE: '同步失败 !',
      },
      BUTTON: {
        ADD: '新增',
        CLOSE: '关闭',
      },
      ALERT: {
        SUBSCRIPTION_DUPLICATE:
          'The client already has the subscription of product that covers the period',
      },
    },
    ECOMMERCE: {
      CUSTOMERS: {
        CUSTOMERS: '顾客',
        CUSTOMERS_LIST: '客户名单',
        NEW_CUSTOMER: '新增客户',
      },
    },
    REGISTER: {
      HI: '您好',
      MESSAGE: '距离产品仅一步之遥…',
      TITLE: '立刻激活您的账户',
      PASSWORD: '密码',
      CONFIRM_PASSWORD: '确认密码',
      SUBMIT_BUTTON: '提交',
    },
    TRIAL_SIGNUP: {
      TITLE: '立即开始14天的免费体验',
      COMPANY_NAME: '公司名称',
      FIRST_NAME: '名',
      LAST_NAME: '姓',
      PHONE: '电话',
      JOB_TITLE: '职位',
      EMAIL: '邮箱',
      PRODUCT: '产品',
      IAGREE: '我已阅读并同意',
      TERMS_AND_CONDITIONS: '试用协议',
      MUSTACCEPT: '您必须同意试用协议',
      SUBMIT: '提交',
      CANCEL: '取消',
      SUCCESS: '您的信息已成功提交。我们会尽快与您联系，谢谢。',
    },
  },
}
