// Actions
import { AuthActions, AuthActionTypes } from '../_actions/auth.actions';
// Models
import { User } from '../_models/user.model';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export interface AuthState {
    loggedIn: boolean;
    // authToken: string;
    user: User;
    // isUserLoaded: boolean;
}

export const initialAuthState: AuthState = {
    loggedIn: false,
    // authToken: undefined,
    user: undefined
    // isUserLoaded: false
};

export function authReducer(state = initialAuthState, action: AuthActions): AuthState {
    switch (action.type) {
        case AuthActionTypes.LoginSuccess: {
            const _user: User = action.payload;
            return {
                loggedIn: true,
                // authToken: _token,
                user: _user
                // isUserLoaded: false
            };
        }

        

        default:
            return state;
    }
}

const getFeatureState = createFeatureSelector<AuthState>("auth");

export const getAuthUser = createSelector(
    getFeatureState,
    (state: AuthState) => state.user
)

export const isLoggedIn = createSelector(
    getFeatureState,
    (state: AuthState) => state.loggedIn
)

export const authStatus = createSelector(
    getFeatureState,
    (state: AuthState) => state
);
