// Angular
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// RxJS
import { Observable, forkJoin, of } from 'rxjs';
import { mergeMap, delay, map } from 'rxjs/operators';
// Lodash
import { each } from 'lodash';
// CRUD
import { HttpUtilsService, QueryParamsModel, QueryResultsModel } from '../../_base/crud';
// Models
import { SubscriptionModel } from '../_models/subscription.model';
import { Store } from '@ngrx/store';
import { ConfigService } from '../../../../app/config.service';

const API_CLIENTS_URL = 'api/users';

interface SubscriptionTypesResponse {
  code: string;
  msg: string;
  data: {moduleId: number, roleCode: string, roleName: string}[];
}
//const BACKEND_URL = 'http://localhost:8095';

//US TEST
//const BACKEND_URL = 'http://ehs-dev.usequantum.com';

//US PRD
// const BACKEND_URL = 'http://ehs.usequantum.com';

//CN TEST
//const BACKEND_URL = 'http://ehs-dev.usequantum.com.cn';

//CN PRD
//const BACKEND_URL = 'http://saas.usequantum.com.cn';

// Fake REST API (Mock)
// This code emulates server calls
@Injectable()
export class SubscriptionService {
  BACKEND_URL = this.configService.SAAS_DOMAIN;
  constructor(
    private http: HttpClient,
    private httpUtils: HttpUtilsService,
    private store: Store<any>,
    private configService: ConfigService
  ) {}

  // CREATE =>  POST: add a new Subscription to the server
  createSubscription(data: object) {
    // Note: Add headers if needed (tokens/bearer)
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    return this.http.post(this.BACKEND_URL + '/system/subscribe', data, {
      headers: httpHeaders,
      responseType: 'text',
    });
  }

  isSubscriptionValid(data: object) {
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    return this.http.post(this.BACKEND_URL + '/system/isSubscriptionValid', data, {
      headers: httpHeaders,
      responseType: 'text',
    });
  }

  loadAllSubscriptions(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
    // This code imitates server calls
    const url = API_CLIENTS_URL;
    let userId = -1;
    this.store.subscribe((state) => {
      if (state.auth.user.userType !== 'QUANTUM_ADMIN') {
        userId = state.auth.user.userId;
      }
    });

    return this.http.get<SubscriptionModel[]>(this.BACKEND_URL + '/system/subscription?userId=' + userId).pipe(
      mergeMap((res) => {
        const result = this.httpUtils.baseFilter(res, queryParams, ['subscriptionStatus', 'productCode']);
        console.log(result);
        return of(result);
      })
    );
  }

  loadSubscriptionHistory({clientId, productId}: {clientId: number, productId: number}) {
    return this.http.post<Object[]>(this.BACKEND_URL + '/system/subscriptionHistory', {clientId, productId});
  }

  syncSubscription(clientId: number, productId: number) {
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    const data = {
      clientId,
      productId
    };
    return this.http.put(this.BACKEND_URL + '/system/updateDB', data, {
      headers: httpHeaders,
      responseType: 'text',
    });
  }

  loadAllGeneralClients() {
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    return this.http.get(this.BACKEND_URL + '/system/generalClients');
  }

  loadAllProducts() {
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    return this.http.get(this.BACKEND_URL + '/system/products');
  }

  loadAllSubscriptionTypes(moduleId: number): Observable<SubscriptionTypesResponse> {
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    return this.http.get<SubscriptionTypesResponse>(this.BACKEND_URL + `/system/roleModule/module/${moduleId}`);
  }

  activateSubscription(data) {
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    return this.http.post(this.BACKEND_URL + '/system/subscribe', data, {
      headers: httpHeaders,
      responseType: 'text',
    });
  }

  cancelSubscription({id, clientId, productId}: {[key in 'id' | 'clientId' | 'productId']: number}) {
    // using destructuring to get params
    let payload = {
      id,
      clientId,
      productId
    };
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    return this.http.put(this.BACKEND_URL + `/system/cancelSubscription`, payload, {
      headers: httpHeaders,
      responseType: 'text',
    });
  }
}
