// NGRX
import { createFeatureSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter, Update } from '@ngrx/entity';
// Actions
import { ClientActions, ClientActionTypes } from '../_actions/client.actions';
// Models
import { ClientModel } from '../_models/client.model';
import { QueryParamsModel } from '../../_base/crud';

export interface ClientsState extends EntityState<ClientModel> {
  listLoading: boolean;
  actionsloading: boolean;
  totalCount: number;
  lastCreatedClientId: number;
  lastQuery: QueryParamsModel;
  showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<ClientModel> = createEntityAdapter<ClientModel>();

export const initialClientsState: ClientsState = adapter.getInitialState({
  clientForEdit: null,
  listLoading: false,
  actionsloading: false,
  totalCount: 0,
  lastCreatedClientId: undefined,
  lastQuery: new QueryParamsModel({}),
  showInitWaitingMessage: true,
});

export function clientsReducer(state = initialClientsState, action: ClientActions): ClientsState {
  switch (action.type) {
    case ClientActionTypes.ClientsPageToggleLoading: {
      return {
        ...state,
        listLoading: action.payload.isLoading,
        lastCreatedClientId: undefined,
      };
    }
    case ClientActionTypes.ClientActionToggleLoading: {
      return {
        ...state,
        actionsloading: action.payload.isLoading,
      };
    }
    case ClientActionTypes.ClientOnServerCreated:
      return {
        ...state,
      };
    case ClientActionTypes.ClientCreated:
      return adapter.addOne(action.payload.client, {
        ...state,
        lastCreatedClientId: action.payload.client.id,
      });
    case ClientActionTypes.ClientUpdated:
      return adapter.updateOne(action.payload.partialClient, state);
    // case ClientActionTypes.ClientsStatusUpdated: {
    //     const _partialClients: Update<ClientModel>[] = [];
    //     // tslint:disable-next-line:prefer-const
    //     for (let i = 0; i < action.payload.clients.length; i++) {
    //         _partialClients.push({
    // 		    id: action.payload.clients[i].id,
    // 		    changes: {
    //                 status: action.payload.status
    //             }
    // 	    });
    //     }
    //     return adapter.updateMany(_partialClients, state);
    // }
    case ClientActionTypes.OneClientDeleted:
      return adapter.removeOne(action.payload.id, state);
    case ClientActionTypes.ManyClientsDeleted:
      return adapter.removeMany(action.payload.ids, state);
    case ClientActionTypes.ClientsPageCancelled: {
      return {
        ...state,
        listLoading: false,
        lastQuery: new QueryParamsModel({}),
      };
    }
    case ClientActionTypes.ClientsPageLoaded: {
      return adapter.addMany(action.payload.clients, {
        ...initialClientsState,
        totalCount: action.payload.totalCount,
        listLoading: false,
        lastQuery: action.payload.page,
        showInitWaitingMessage: false,
      });
    }
    case ClientActionTypes.OneClientUpdated: {
      return adapter.updateOne(action.payload.partialClient, state);
    }
    default:
      return state;
  }
}

export const getClientState = createFeatureSelector<ClientModel>('clients');

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
