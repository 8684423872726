import { QueryParamsModel } from '../../_base/crud/models/query-models/query-params.model';
import { forkJoin } from 'rxjs';
// Angular
import { Injectable } from '@angular/core';
// RxJS
import { mergeMap, map, tap, delay } from 'rxjs/operators';
// NGRX
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
// CRUD
import { QueryResultsModel } from '../../_base/crud';
// Services
import { ClientsService } from '../_services';
// State
import { AppState } from '../../reducers';
// Actions
import {
  ClientActionTypes,
  ClientsPageRequested,
  ClientsPageLoaded,
  ManyClientsDeleted,
  OneClientDeleted,
  ClientActionToggleLoading,
  ClientsPageToggleLoading,
  ClientUpdated,
  ClientsStatusUpdated,
  ClientCreated,
  ClientOnServerCreated,
  OneClientUpdated,
} from '../_actions/client.actions';
import { of } from 'rxjs';
import { Update } from '@ngrx/entity';
import { ClientModel } from '..';

@Injectable()
export class ClientEffects {
  showPageLoadingDistpatcher = new ClientsPageToggleLoading({ isLoading: true });
  showActionLoadingDistpatcher = new ClientActionToggleLoading({ isLoading: true });
  hideActionLoadingDistpatcher = new ClientActionToggleLoading({ isLoading: false });

  @Effect()
  loadClientsPage$ = this.actions$.pipe(
    ofType<ClientsPageRequested>(ClientActionTypes.ClientsPageRequested),
    mergeMap(({ payload }) => {
      this.store.dispatch(this.showPageLoadingDistpatcher);
      const requestToServer = this.clientsService.findAllCompanyAdmins(payload.page);
      const lastQuery = of(payload.page);
      return forkJoin(requestToServer, lastQuery);
    }),
    map((response) => {
      const result: QueryResultsModel = response[0];
      const lastQuery: QueryParamsModel = response[1];
      const pageLoadedDispatch = new ClientsPageLoaded({
        clients: result.items,
        totalCount: result.totalCount,
        page: lastQuery,
      });
      return pageLoadedDispatch;
    })
  );

  @Effect()
  deleteClient$ = this.actions$.pipe(
    ofType<OneClientDeleted>(ClientActionTypes.OneClientDeleted),
    mergeMap(({ payload }) => {
      this.store.dispatch(this.showActionLoadingDistpatcher);
      return this.clientsService.deleteClient(payload.id);
    }),
    map(() => {
      return this.hideActionLoadingDistpatcher;
    })
  );

  @Effect()
  deleteClients$ = this.actions$.pipe(
    ofType<ManyClientsDeleted>(ClientActionTypes.ManyClientsDeleted),
    mergeMap(({ payload }) => {
      this.store.dispatch(this.showActionLoadingDistpatcher);
      return this.clientsService.deleteClients(payload.ids);
    }),
    map(() => {
      return this.hideActionLoadingDistpatcher;
    })
  );

  @Effect()
  updateClient$ = this.actions$.pipe(
    ofType<ClientUpdated>(ClientActionTypes.ClientUpdated),
    mergeMap(({ payload }) => {
      this.store.dispatch(this.showActionLoadingDistpatcher);
      return this.clientsService.updateClient(payload.client);
    }),
    map(() => {
      return this.hideActionLoadingDistpatcher;
    })
  );

  // @Effect()
  // loadOneClient$ = this.actions$
  //     .pipe(
  //         ofType<OneClientUpdated>(ClientActionTypes.OneClientUpdated),
  //         mergeMap(( { payload } ) => {
  //             return this.store.dispatch(this.showActionLoadingDistpatcher);
  //             return this.clientsService.updateClient(payload.client);
  //         }),
  //         map(() => {
  //             return this.hideActionLoadingDistpatcher;
  //         })
  //     );

  // @Effect()
  // updateClientsStatus$ = this.actions$
  //     .pipe(
  //         ofType<ClientsStatusUpdated>(ClientActionTypes.ClientsStatusUpdated),
  //         mergeMap(( { payload } ) => {
  //             this.store.dispatch(this.showActionLoadingDistpatcher);
  //             return this.clientsService.updateStatusForClient(payload.clients, payload.status);
  //         }),
  //         map(() => {
  //             return this.hideActionLoadingDistpatcher;
  //         })
  //     );

  // @Effect()
  // createClient$ = this.actions$
  //     .pipe(
  //         ofType<ClientOnServerCreated>(ClientActionTypes.ClientOnServerCreated),
  //         mergeMap(( { payload } ) => {
  //             this.store.dispatch(this.showActionLoadingDistpatcher);
  //             return this.clientsService.createClient(payload.client).pipe(
  //                 tap(res => {
  //                     this.store.dispatch(new ClientCreated({ client: res }));
  //                 })
  //             );
  //         }),
  //         map(() => {
  //             return this.hideActionLoadingDistpatcher;
  //         }),
  //     );
  @Effect()
  createClient$ = this.actions$.pipe(
    ofType<ClientOnServerCreated>(ClientActionTypes.ClientOnServerCreated),
    mergeMap(({ payload }) => {
      this.store.dispatch(this.showActionLoadingDistpatcher);
      return this.clientsService.createClient(payload.client).pipe(
        tap(() => {
          //this.store.dispatch(new ClientCreated({ client: payload.client }));
        })
      );
    }),
    map(() => {
      return this.hideActionLoadingDistpatcher;
    })
  );

  constructor(private actions$: Actions, private clientsService: ClientsService, private store: Store<AppState>) {}
}
