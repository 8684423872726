import { BaseModel } from '../../_base/crud';

export class User extends BaseModel {
  id: number;
  client_id: number;
  login_id: string;
  password: string;
  email_address: string;
  type: number;
  status: number;
  phone: string;
  job_title: string;
  userType?: string;
  // pic: string;

  clear(): void {
    this.id = undefined;
    this.login_id = '';
    this.password = '';
    this.email_address = '';
    this.phone = '';
    this.job_title = '';
    // this.pic = './assets/media/users/default.jpg';
  }
}
