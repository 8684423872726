import { Component, OnInit, Inject, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'kt-dashboard-dialog',
  templateUrl: './dashboard-dialog.component.html',
  styleUrls: ['./dashboard-dialog.component.scss']
})
export class DashboardDialogComponent implements OnInit {
/**
	 * Component constructor
	 *
	 * @param dialogRef: MatDialogRef<DeleteEntityDialogComponent>
	 * @param data: any
	 */
	constructor( 
		@Optional() public dialogRef: MatDialogRef<DashboardDialogComponent>,
		@Optional() @Inject(MAT_DIALOG_DATA) public data: any
	) { }

  ngOnInit() {
  }

  onYesClick(){
    this.dialogRef.close();
  }

}
