// Angular
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
// RxJS
import { Observable } from 'rxjs';
import { tap, map } from 'rxjs/operators';
// NGRX
import { select, Store } from '@ngrx/store';
// Auth reducers and selectors
import { AppState} from '../../../core/reducers/';
// import { isLoggedIn } from '../_selectors/auth.selectors';

import { isLoggedIn } from '../../saas/_reducers/auth.reducer';
import { authStatus } from '../../saas/_reducers/auth.reducer';

@Injectable()
export class AdminGuard implements CanActivate {
    constructor(private store: Store<AppState>, private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean>  {
        return this.store
            .pipe(
                select(authStatus),
                map(auth => {
                  if (auth.user.userType && auth.user.userType === "QUANTUM_ADMIN") {
                    return true;
                  }
                  return false;
                }),
                tap(canAccess => {
                    if (!canAccess) {
                        this.router.navigateByUrl('/auth/login');
                    }
                })
            );
    }
}
