import { QueryParamsModel } from '../../_base/crud/models/query-models/query-params.model';
import { forkJoin } from 'rxjs';
// Angular
import { Injectable } from '@angular/core';
// RxJS
import { mergeMap, map, tap } from 'rxjs/operators';
// NGRX
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
// CRUD
import { QueryResultsModel } from '../../_base/crud';
// Services
import { SubscriptionService } from '../_services/subscription.service';
// State
import { AppState } from '../../reducers';
// Actions
import {
  SubscriptionPageToggleLoading,
  SubscriptionActionToggleLoading,
  SubscriptionActionTypes,
  SubscriptionPageRequested,
  SubscriptionPageLoaded,
  SubscriptionOnServerCreated,
  SubscriptionCreated,
} from '../_actions/subscription.actions';
import { of } from 'rxjs';
import { Update } from '@ngrx/entity';

@Injectable()
export class SubscriptionEffects {
  showPageLoadingDistpatcher = new SubscriptionPageToggleLoading({ isLoading: true });
  showActionLoadingDistpatcher = new SubscriptionActionToggleLoading({ isLoading: true });
  hideActionLoadingDistpatcher = new SubscriptionActionToggleLoading({ isLoading: false });

  @Effect()
  loadSubscriptionPage$ = this.actions$.pipe(
    ofType<SubscriptionPageRequested>(SubscriptionActionTypes.SubscriptionPageRequested),
    mergeMap(({ payload }) => {
      this.store.dispatch(this.showPageLoadingDistpatcher);
      const requestToServer = this.subscriptionService.loadAllSubscriptions(payload.page);
      const lastQuery = of(payload.page);
      return forkJoin(requestToServer, lastQuery);
    }),
    map((response) => {
      console.log(response);
      const result: QueryResultsModel = response[0];
      const lastQuery: QueryParamsModel = response[1];
      const pageLoadedDispatch = new SubscriptionPageLoaded({
        subscription: result.items,
        totalCount: result.totalCount,
        page: lastQuery,
      });
      return pageLoadedDispatch;
    })
  );

  @Effect()
  createSubscription$ = this.actions$.pipe(
    ofType<SubscriptionOnServerCreated>(SubscriptionActionTypes.SubscriptionOnServerCreated),
    mergeMap(({ payload }) => {
      this.store.dispatch(this.showActionLoadingDistpatcher);
      return this.subscriptionService.createSubscription(payload.subscription).pipe(tap(() => {}));
    }),
    map(() => {
      return this.hideActionLoadingDistpatcher;
    })
  );

  constructor(private actions$: Actions, private subscriptionService: SubscriptionService, private store: Store<AppState>) {}
}
