// NGRX
import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
// CRUD
import { QueryParamsModel } from '../../_base/crud';
// Models
import { ClientModel } from '../_models/client.model';

export enum ClientActionTypes {
    ClientOnServerCreated = '[Edit Client Dialog] Client On Server Created',
    ClientCreated = '[Edit Client Dialog] Client Created',
    ClientUpdated = '[Edit Client Dialog] Client Updated',
    ClientsStatusUpdated = '[Client List Page] Clients Status Updated',
    OneClientDeleted = '[Clients List Page] One Client Deleted',
    ManyClientsDeleted = '[Clients List Page] Many Client Deleted',
    ClientsPageRequested = '[Clients List Page] Clients Page Requested',
    ClientsPageLoaded = '[Clients API] Clients Page Loaded',
    ClientsPageCancelled = '[Clients API] Clients Page Cancelled',
    ClientsPageToggleLoading = '[Clients] Clients Page Toggle Loading',
    ClientActionToggleLoading = '[Clients] Clients Action Toggle Loading',
    OneClientUpdated = '[Clients API] One Client Updated'
}
export class OneClientUpdated implements Action {
    readonly type = ClientActionTypes.OneClientUpdated;
    constructor(public payload: {
        partialClient: Update<ClientModel>, // For State update
        client: ClientModel // For Server update (through service)
    }) { }
}



export class ClientOnServerCreated implements Action {
    readonly type = ClientActionTypes.ClientOnServerCreated;
    constructor(public payload: { client: ClientModel }) { }
}

export class ClientCreated implements Action {
    readonly type = ClientActionTypes.ClientCreated;
    constructor(public payload: { client: ClientModel }) { }
}

export class ClientUpdated implements Action {
    readonly type = ClientActionTypes.ClientUpdated;
    constructor(public payload: {
        partialClient: Update<ClientModel>, // For State update
        client: ClientModel // For Server update (through service)
    }) { }
}

export class ClientsStatusUpdated implements Action {
    readonly type = ClientActionTypes.ClientsStatusUpdated;
    constructor(public payload: {
        clients: ClientModel[],
        status: number
    }) { }
}

export class OneClientDeleted implements Action {
    readonly type = ClientActionTypes.OneClientDeleted;
    constructor(public payload: { id: number }) {}
}

export class ManyClientsDeleted implements Action {
    readonly type = ClientActionTypes.ManyClientsDeleted;
    constructor(public payload: { ids: number[] }) {}
}

export class ClientsPageRequested implements Action {
    readonly type = ClientActionTypes.ClientsPageRequested;
    constructor(public payload: { page: QueryParamsModel }) { }
}


export class ClientsPageLoaded implements Action {
    readonly type = ClientActionTypes.ClientsPageLoaded;
    constructor(public payload: { clients: ClientModel[], totalCount: number, page: QueryParamsModel }) { }
}

export class ClientsPageCancelled implements Action {
    readonly type = ClientActionTypes.ClientsPageCancelled;
}

export class ClientsPageToggleLoading implements Action {
    readonly type = ClientActionTypes.ClientsPageToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export class ClientActionToggleLoading implements Action {
    readonly type = ClientActionTypes.ClientActionToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export type ClientActions = ClientOnServerCreated
| ClientCreated
| ClientUpdated
| ClientsStatusUpdated
| OneClientDeleted
| ManyClientsDeleted
| ClientsPageRequested
| ClientsPageLoaded
| ClientsPageCancelled
| ClientsPageToggleLoading
| ClientActionToggleLoading
| OneClientUpdated;
