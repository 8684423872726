// Angular
import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
// RxJS
import { Subscription } from 'rxjs';
// Layout
import { SubheaderService } from '../../../../../core/_base/layout';
import { Breadcrumb } from '../../../../../core/_base/layout/services/subheader.service';
// Translate Module
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'kt-subheader3',
  templateUrl: './subheader3.component.html',
  styleUrls: ['./subheader3.component.scss'],
})
export class Subheader3Component implements OnInit, OnDestroy, AfterViewInit {
  // Public properties
  @Input() fluid: boolean;
  @Input() clear: boolean;

  today: number = Date.now();
  title = '';
  desc = '';
  breadcrumbs: Breadcrumb[] = [];

  // Private properties
  private subscriptions: Subscription[] = [];

  /**
   * Component constructor
   *
   * @param subheaderService: SubheaderService
   */
  constructor(public subheaderService: SubheaderService, private translate: TranslateService) {}

  /**
   * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
   */

  /**
   * On init
   */
  ngOnInit() {}

  /**
   * After view init
   */
  ngAfterViewInit(): void {
    this.subscriptions.push(
      this.subheaderService.title$.subscribe((bt) => {
        // breadcrumbs title sometimes can be undefined
        if (bt) {
          Promise.resolve(null).then(() => {
            this.title = bt.title;
            this.desc = bt.desc;
            console.log('title = ', this.title);
            if (this.title === 'Dashboard') {
              this.title = this.translate.instant('MENU.DASHBOARD');
            } else if (this.title === 'Users') {
              this.title = this.translate.instant('MENU.USERS');
            } else if (this.title === 'Clients') {
              this.title = this.translate.instant('CLIENT.TITLE.CLIENTS');
            } else if (this.title === 'Subscriptions') {
              this.title = this.translate.instant('MENU.SUBSCRIPTIONS');
            }
          });
        }
      })
    );

    this.subscriptions.push(
      this.subheaderService.breadcrumbs$.subscribe((bc) => {
        Promise.resolve(null).then(() => {
          this.breadcrumbs = bc;
        });
      })
    );
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach((sb) => sb.unsubscribe());
  }
}
