import { Injectable } from '@angular/core'
import { AES, mode, pad } from 'crypto-js'
import CryptoJS from 'crypto-js'
import { environment } from '../environments/environment'
import { Products } from '../app/core/saas/_models/products.model'
import { TranslateService } from '@ngx-translate/core'

export enum SNACKBAR {
  SYNC_SUCCESS,
  SYNC_FAILED,
  CANCEL_SUBSCRIPTION_SUCCESS,
  CANCEL_SUBSCRIPTION_FAILED,
  CANCEL_SUBSCRIPTION_CLOSE,
  ACTION_CLOSE,
}

@Injectable({ providedIn: 'root' })
export class ConfigService {
  HTTP = environment.http
  PART3 = '&password='

  SAAS_DOMAIN = environment.saasDomain
  V12_LOGIN = environment.v12LoginUrl
  V13_LOGIN = environment.v13LoginUrl
  SAFETY_OBSERVATION_PART2 = environment.safetyObservationUrl
  SDS_PART2 = environment.sdsUrl
  COVID_PART2 = environment.covidUrl
  REGULATION = environment.regulationUrl
  TRAINING = environment.trainingUrl
  RISK = environment.riskUrl
  INCIDENT = environment.incidentUrl
  // DB_PREFIX = environment.v7DbPrefix ? environment.v7DbPrefix : '';

  constructor(private translate: TranslateService) {}

  getUrl(
    companyId: string,
    loginId: string,
    password: string,
    productName: Products
  ): string {
    let urlMiddlePart = ''
    switch (productName) {
      case Products.TRA:
        urlMiddlePart = this.V13_LOGIN;
        break
      case Products.RISK:
        urlMiddlePart = this.V13_LOGIN;
        break
      case Products.EMER:
        urlMiddlePart = this.V13_LOGIN;
        break
      case Products.INCI:
        urlMiddlePart = this.V13_LOGIN;
        break
      case Products.INSP:
        urlMiddlePart = this.V13_LOGIN;
        break
      case Products.BBSO:
        urlMiddlePart = this.V13_LOGIN;
        break
      case Products.REG:
        urlMiddlePart = this.V13_LOGIN;
        break
      case Products.DOC:
        urlMiddlePart = this.V13_LOGIN;
        break
      case Products.CON:
        urlMiddlePart = this.V13_LOGIN;
        break
      case Products.HAZ:
        urlMiddlePart = this.V13_LOGIN;
        break
      case Products.NOT:
        urlMiddlePart = this.V13_LOGIN;
        break
    }
    console.log(
      'xxxxxxxxxxxxxxxxxxxx',
      this.HTTP,
      companyId,
      urlMiddlePart,
    )
    const URL = this.HTTP + companyId + urlMiddlePart
    console.log('URL: ' + URL)
    return URL
  }

  encryptByEnAES(data: string, key: string): string {
    const dataUft = CryptoJS.enc.Utf8.parse(data)
    const keyUtf = CryptoJS.enc.Utf8.parse(key)
    const iv = CryptoJS.enc.Utf8.parse(key)
    const tmpAES = AES.encrypt(dataUft, keyUtf, {
      mode: mode.CBC,
      padding: pad.Pkcs7,
      iv,
      keySize: 32,
    })

    return tmpAES.toString()
  }

  translateProductName(productName: string): string {
    let res = ''
    switch (productName) {
      case 'Safety Observation':
        res = this.translate.instant('PRODUCT.SAFETY_OBSERVATION')
        break
      case 'Chemical Management':
        res = this.translate.instant('PRODUCT.Chemical_Management')
        break
      case 'COVID Check':
        res = this.translate.instant('PRODUCT.COVID_CHECK')
        break
      case 'Regulation':
        res = this.translate.instant('PRODUCT.REGULATION')
        break
      case 'Training':
        res = this.translate.instant('PRODUCT.TRAINING')
        break
      case 'Risk':
        res = this.translate.instant('PRODUCT.RISK')
        break
      case 'Incident':
        res = this.translate.instant('PRODUCT.INCIDENT')
        break
      default:
        break
    }
    return res
  }

  translateSnackBarMessage(messageType: SNACKBAR): string {
    let res = ''

    switch (messageType) {
      case SNACKBAR.SYNC_SUCCESS:
        res = this.translate.instant('SUBSCRIPTION.SYNC.SUCCESS_MESSAGE')
        break
      case SNACKBAR.SYNC_FAILED:
        res = this.translate.instant('SUBSCRIPTION.SYNC.FAIL_MESSAGE')
        break
      case SNACKBAR.CANCEL_SUBSCRIPTION_SUCCESS:
        res = this.translate.instant('SUBSCRIPTION.UNSUBSCRIBE.SUCCESS_MESSAGE')
        break
      case SNACKBAR.CANCEL_SUBSCRIPTION_FAILED:
        res = this.translate.instant('SUBSCRIPTION.UNSUBSCRIBE.FAIL_MESSAGE')
        break
      default:
        break
    }

    return res
  }

  translateSnackbarAction(actionType: SNACKBAR): string {
    let res = ''

    switch (actionType) {
      case SNACKBAR.ACTION_CLOSE:
        res = this.translate.instant('SUBSCRIPTION.BUTTON.CLOSE')
        break
      default:
        break
    }

    return res
  }
}
