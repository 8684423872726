// Foundation
import { Component, OnInit, ElementRef, ViewChild, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { MatPaginator, MatSort, MatSnackBar, MatDialog } from '@angular/material';

// RxJs
import { debounceTime, distinctUntilChanged, tap, skip, delay, take } from 'rxjs/operators';
import { fromEvent, merge, Subscription, of } from 'rxjs';

// Components
import { ClientEditDialogComponent } from '../client-edit/client-edit.dialog.component';

// Service
import { TranslateService } from '@ngx-translate/core';
import { LayoutUtilsService, MessageType } from '../../../../../../core/_base/crud';
import {
  ClientModel,
  ClientsDataSource,
  ClientsPageRequested,
  OneClientDeleted,
  ManyClientsDeleted,
  ClientsStatusUpdated,
} from '../../../../../../core/saas';

// NGRX
import { Store } from '@ngrx/store';
import { AppState } from '../../../../../../core/reducers';

// Model
import { QueryParamsModel } from '../../../../../../core/_base/crud';

@Component({
  selector: 'kt-clients-list',
  templateUrl: './clients-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./clients-list.component.scss'],
})
export class ClientsListComponent implements OnInit, OnDestroy {
  // Table fields
  tempClient: ClientModel;
  isAddClientButton = false;
  dataSource: ClientsDataSource;
  displayedColumns = ['companyName', 'companyId', 'adminUser', 'status', 'actions'];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('sort1', { static: true }) sort: MatSort;
  // Filter fields
  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;
  filterStatus = '';
  filterProduct = '';
  // Selection
  selection = new SelectionModel<ClientModel>(true, []);
  clientsResult: ClientModel[] = [];
  // Subscriptions
  private subscriptions: Subscription[] = [];

  constructor(
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private layoutUtilsService: LayoutUtilsService,
    private translate: TranslateService,
    private store: Store<AppState>
  ) {}

  // Life Cycles
  // ------------------------------------------------------------------------------------

  ngOnInit() {
    // If the user changes the sort order, reset back to the first page.
    const sortSubscription = this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
    this.subscriptions.push(sortSubscription);

    /* Data load will be triggered in two cases:
		- when a pagination event occurs => this.paginator.page
		- when a sort event occurs => this.sort.sortChange
		**/
    const paginatorSubscriptions = merge(this.sort.sortChange, this.paginator.page)
      .pipe(tap(() => this.loadClientsList()))
      .subscribe();
    this.subscriptions.push(paginatorSubscriptions);

    // Filtration, bind to searchInput
    const searchSubscription = fromEvent(this.searchInput.nativeElement, 'keyup')
      .pipe(
        // tslint:disable-next-line:max-line-length
        debounceTime(50), // The user can type quite quickly in the input box, and that could trigger a lot of server requests. With this operator, we are limiting the amount of server requests emitted to a maximum of one every 150ms
        distinctUntilChanged(), // This operator will eliminate duplicate values
        tap(() => {
          this.paginator.pageIndex = 0;
          this.loadClientsList();
        })
      )
      .subscribe();
    this.subscriptions.push(searchSubscription);

    // Init DataSource
    this.dataSource = new ClientsDataSource(this.store);

    const entitiesSubscription = this.dataSource.entitySubject.pipe(skip(1), distinctUntilChanged()).subscribe((res) => {
      this.clientsResult = res;
    });
    this.subscriptions.push(entitiesSubscription);
    // First load
    of(undefined)
      .pipe(take(1), delay(1000))
      .subscribe(() => {
        // Remove this line, just loading imitation
        this.loadClientsList();
      }); // Remove this line, just loading imitation
  }

  ngOnDestroy() {
    this.subscriptions.forEach((el) => el.unsubscribe());
  }

  loadClientsList() {
    this.selection.clear();
    const queryParams = new QueryParamsModel(
      this.filterConfiguration(),
      this.sort.direction,
      this.sort.active,
      this.paginator.pageIndex,
      this.paginator.pageSize
    );
    // Call request from server
    this.store.dispatch(new ClientsPageRequested({ page: queryParams }));
    this.selection.clear();
  }

  filterConfiguration(): any {
    const filter: any = {};
    const searchText: string = this.searchInput.nativeElement.value;

    // if (this.filterStatus && this.filterStatus.length > 0) {
    //   filter.status = +this.filterStatus;
    // }

    // if (this.filterProduct && this.filterProduct.length > 0) {
    //   filter.products = this.filterProduct;
    // }

    filter.companyName = searchText;
    filter.companyId = searchText;
    filter.adminUser = searchText;
    return filter;
  }

  deleteClient(item: ClientModel) {
    const title: string = this.translate.instant('ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_SIMPLE.TITLE');
    const description: string = this.translate.instant('ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_SIMPLE.DESCRIPTION');
    const waitDescription: string = this.translate.instant('ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_SIMPLE.WAIT_DESCRIPTION');
    const deleteMessage = this.translate.instant('ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_SIMPLE.MESSAGE');

    const dialogRef = this.layoutUtilsService.deleteElement(title, description, waitDescription);
    dialogRef.afterClosed().subscribe((res) => {
      if (!res) {
        return;
      }

      this.store.dispatch(new OneClientDeleted({ id: item.id }));
      this.layoutUtilsService.showActionNotification(deleteMessage, MessageType.Delete);
    });
  }

  getFullWord(input: string) {
    input = input.toLowerCase();
    let res = '';
    for (let i = 0; i < input.length; i++) {
      if (i === 0 || (i - 1 >= 0 && input.charAt(i - 1) === '_')) {
        res += input.charAt(i).toUpperCase();
      } else {
        res += input.charAt(i);
      }
    }
    res = res.replace('_', ' ');
    return res;
  }

  /**
   * Delete selected clients
   */
  deleteClients() {
    const title: string = this.translate.instant('ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_MULTY.TITLE');
    const description: string = this.translate.instant('ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_MULTY.DESCRIPTION');
    const waitDescription: string = this.translate.instant('ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_MULTY.WAIT_DESCRIPTION');
    const deleteMessage = this.translate.instant('ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_MULTY.MESSAGE');

    const dialogRef = this.layoutUtilsService.deleteElement(title, description, waitDescription);
    dialogRef.afterClosed().subscribe((res) => {
      if (!res) {
        return;
      }

      const idsForDeletion: number[] = [];
      for (const selectedItem of this.selection.selected) {
        idsForDeletion.push(selectedItem.id);
      }
      this.store.dispatch(new ManyClientsDeleted({ ids: idsForDeletion }));
      this.layoutUtilsService.showActionNotification(deleteMessage, MessageType.Delete);
      this.selection.clear();
    });
  }

  addClient() {
    const newClient = new ClientModel();
    newClient.clear(); // Set all defaults fields
    this.isAddClientButton = true;
    this.editClient(newClient);
    this.isAddClientButton = false;
  }

  editClient(client: ClientModel) {
    let dialogRef;
    const date = new Date();
    const time = date.getHours() + ':' + date.getMinutes();

    console.log('time: ' + time + ': list page, this.isAddClientButton = ' + this.isAddClientButton);
    if (this.isAddClientButton) {
      dialogRef = this.dialog.open(ClientEditDialogComponent, { data: { client, isAddOrEdit: 'add' } });
    } else {
      dialogRef = this.dialog.open(ClientEditDialogComponent, { data: { client, isAddOrEdit: 'edit' } });
    }

    dialogRef.afterClosed().subscribe((res) => {
      if (!res) {
        return;
      }
      this.loadClientsList();
    });
  }

  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.clientsResult.length;
    return numSelected === numRows;
  }

  masterToggle() {
    if (this.selection.selected.length === this.clientsResult.length) {
      this.selection.clear();
    } else {
      this.clientsResult.forEach((row) => this.selection.select(row));
    }
  }

  getItemCssClassByStatus(status: string): string {
    switch (status) {
      case 'ACTIVE':
        return 'success';
      case 'PENDING':
        return 'metal';
    }
    return '';
  }

  getItemStatusString(status: number = 0): string {
    switch (status) {
      case 0:
        return 'Suspended';
      case 1:
        return this.translate.instant('USER.SEARCH.ACTIVE');
      case 2:
        return this.translate.instant('USER.SEARCH.PENDING');
      case 3:
        return this.translate.instant('USER.SEARCH.INACTIVE');
    }
    return '';
  }

  getItemProductString(status: number = 0): string {
    switch (status) {
      case 0:
        return 'Safety Observation';
      case 1:
        return 'Safety Observation2';
    }
    return '';
  }

  getItemCssClassByType(status: number = 0): string {
    switch (status) {
      case 0:
        return 'accent';
      case 1:
        return 'primary';
      case 2:
        return '';
    }
    return '';
  }

  getItemTypeString(status: number = 0): string {
    switch (status) {
      case 0:
        return 'Business';
      case 1:
        return 'Individual';
    }
    return '';
  }
}
