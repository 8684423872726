// Foundation
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// RxJs
import { finalize, takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';

// Service
import { TranslateService } from '@ngx-translate/core';
import { CommunicationService } from './../../../../../../src/app/core/auth/_services/communication.service';
import { ConfigService } from '../../../../../app/config.service';
import { AuthNoticeService, AuthService } from '../../../../core/auth/';
// NGRX
import { Store } from '@ngrx/store';
import { AppState } from '../../../../core/reducers';

import { ConfirmPasswordValidator } from './confirm-password.validator';

// Translate
import { TranslationService } from '../../../../core/_base/layout';

@Component({
  selector: 'kt-reset',
  templateUrl: './reset-password.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  resetForm: FormGroup;
  loading = false;
  errors: any = [];
  @Output() public childEvent = new EventEmitter();
  public userId: number;
  public companyId: string;
  public isCompanyAdmin: number;
  public productType: string;
  public emailAddress: string;
  public userLanguage: string;
  private unsubscribe: Subject<any>;

  constructor(
    private authNoticeService: AuthNoticeService,
    private translate: TranslateService,
    private authService: AuthService,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private communicationService: CommunicationService,
    private activatedRoute: ActivatedRoute,
    private configService: ConfigService,
    private translationService: TranslationService
  ) {
    this.unsubscribe = new Subject();
  }

  ngOnInit() {
    this.communicationService.emitChange('Quantum Helps You Align Your Business Goals with EHS Compliance and Leadership.');
    this.userId = parseInt(this.activatedRoute.snapshot.paramMap.get('userId'), 10);
    this.companyId = this.activatedRoute.snapshot.paramMap.get('companyId');
    this.isCompanyAdmin = parseInt(this.activatedRoute.snapshot.paramMap.get('isCompanyAdmin'), 10);
    this.productType = this.activatedRoute.snapshot.paramMap.get('productType');
    this.emailAddress = this.activatedRoute.snapshot.paramMap.get('emailAddress');
    this.userLanguage = this.activatedRoute.snapshot.paramMap.get('userLanguage');
    this.initResetForm();
    this.translationService.setLanguage(this.userLanguage);
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
    this.loading = false;
  }

  initResetForm() {
    this.resetForm = this.fb.group(
      {
        password: ['', Validators.compose([Validators.required, Validators.minLength(4)])],
        confirmPassword: ['', Validators.required],
      },
      {
        validator: ConfirmPasswordValidator.MatchPassword,
      }
    );
  }

  /**
   * Form Submit
   */
  submit() {
    const controls = this.resetForm.controls;
    /** check form */
    if (this.resetForm.invalid) {
      Object.keys(controls).forEach((controlName) => controls[controlName].markAsTouched());
      return;
    }

    this.loading = true;
    const password = controls.password.value;
    if (this.userId == null) {
      return;
    }
    let isCompanyAdminBoolean: boolean;
    if (this.isCompanyAdmin === 1) {
      isCompanyAdminBoolean = true;
    } else {
      isCompanyAdminBoolean = false;
    }
    this.authService
      .resetPassword(this.userId, password, this.companyId, isCompanyAdminBoolean)
      .pipe(
        tap((response) => {
          if (response) {
            this.authNoticeService.setNotice(this.translate.instant('AUTH.FORGOT.SUCCESS'), 'success');

            setTimeout(() => {
              const userDomain = 'https://' + this.companyId;
              if (this.productType === 'v12'){
                window.location.href = userDomain + this.configService.V12_LOGIN;
              } else if (this.productType === 'v13') {
                window.location.href = userDomain + this.configService.V13_LOGIN;
              }  
            }, 10000);
          } else {
            this.authNoticeService.setNotice(
              this.translate.instant('AUTH.VALIDATION.NOT_FOUND', { name: this.translate.instant('AUTH.INPUT.EMAIL') }),
              'danger'
            );
          }
        }),
        takeUntil(this.unsubscribe),
        finalize(() => {
          this.loading = false;
          this.cdr.markForCheck();
        })
      )
      .subscribe();
  }

  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.resetForm.controls[controlName];
    if (!control) {
      return false;
    }

    const result = control.hasError(validationType) && (control.dirty || control.touched);
    return result;
  }
}
