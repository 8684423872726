// Angular
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// RxJS
import { finalize, takeUntil, tap } from 'rxjs/operators';
// Translate
import { TranslateService } from '@ngx-translate/core';
// NGRX
import { Store } from '@ngrx/store';
import { AppState } from '../../../../core/reducers';
// Auth
import { AuthNoticeService, AuthService, Register, User } from '../../../../core/auth/';
import { Subject } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { ClientsService } from '../../../../core/saas/_services/clients.service.fake';
import { MatSnackBar } from '@angular/material';

@Component({
	selector: 'kt-register',
	templateUrl: './register.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [ClientsService]
})
export class RegisterComponent implements OnInit, OnDestroy {
	registerForm: FormGroup;
	loading = false;
	errors: any = [];

	private unsubscribe: Subject<any>; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

	/**
	 * Component constructor
	 *
	 * @param authNoticeService: AuthNoticeService
	 * @param translate: TranslateService
	 * @param router: Router
	 * @param auth: AuthService
	 * @param store: Store<AppState>
	 * @param fb: FormBuilder
	 * @param cdr
	 */
	constructor(
		private authNoticeService: AuthNoticeService,
		private translate: TranslateService,
		private router: Router,
		private auth: AuthService,
		private clientService: ClientsService,
		private store: Store<AppState>,
		private fb: FormBuilder,
		private cdr: ChangeDetectorRef,
		private http: HttpClient,
		private snackbar: MatSnackBar,
	) {
		this.unsubscribe = new Subject();
	}

	/*
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
    */

	/**
	 * On init
	 */
	ngOnInit() {
		this.initRegisterForm();
	}

	/*
    * On destroy
    */
	ngOnDestroy(): void {
		this.unsubscribe.next();
		this.unsubscribe.complete();
		this.loading = false;
	}

	/**
	 * Form initalization
	 * Default params, validators
	 */
	initRegisterForm() {
		this.registerForm = this.fb.group({
			companyName: ['', Validators.compose([
				Validators.required,
				Validators.minLength(2),
				Validators.maxLength(100)
			])
			],
			companyId: ['', Validators.compose([
				Validators.required,
				Validators.minLength(2),
				Validators.maxLength(10),
				Validators.pattern(/^[A-Za-z0-9]+$/),
			])
			],
			firstname: ['', Validators.compose([
				Validators.required,
				Validators.maxLength(100)
			])
			],
			lastname: ['', Validators.compose([
				Validators.required,
				Validators.maxLength(100)
			])
			],
			email: ['', Validators.compose([
				Validators.required,
				Validators.email,
				Validators.minLength(3),
				// https://stackoverflow.com/questions/386294/what-is-the-maximum-length-of-a-valid-email-address
				Validators.maxLength(320)
			]),
			],
		});
	}

	/**
	 * Form Submit
	 */
	submit() {
		const controls = this.registerForm.controls;

		// check form
		if (this.registerForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
			return;
		}

		const body = {
			loginId: controls.email.value,
			userEmailAddress: controls.email.value,
			userType: "COMPANY_ADMIN",
			userStatus: "PENDING",
			userFirstName: controls.firstname.value,
			userLastName: controls.lastname.value,
			userLanguage: "EN",
			companyId: controls.companyId.value,
			companyName: controls.companyName.value,
			companyType: "GENERAL_CLIENT",
		}

		this.clientService.isCompanyIdDuplicate(body.companyId, 0).subscribe(isDuplicate => {
			if (isDuplicate === 'true') {
				this.snackbar.open("Sorry, that company id is already taken. Please choose a different one.", '',
					{
						duration: 5000
					}
				);
			} else {
				this.auth.selfSignUp(body).subscribe();
				this.router.navigateByUrl('/auth/confirmation');
			}
		});
		
	}

	/**
	 * Checking control validation
	 *
	 * @param controlName: string => Equals to formControlName
	 * @param validationType: string => Equals to valitors name
	 */
	isControlHasError(controlName: string, validationType: string): boolean {
		const control = this.registerForm.controls[controlName];
		if (!control) {
			return false;
		}

		const result = control.hasError(validationType) && (control.dirty || control.touched);
		return result;
	}
}
