import { mergeMap, tap } from 'rxjs/operators';
// RxJS
import { delay, distinctUntilChanged, skip, filter, take, map } from 'rxjs/operators';
// NGRX
import { Store, select } from '@ngrx/store';
// CRUD
import { BaseDataSource, QueryResultsModel } from '../../_base/crud';
// State
import { AppState } from '../../reducers';
import { selectClientsInStore, selectClientsPageLoading, selectClientsShowInitWaitingMessage } from '../_selectors/client.selectors';

export class ClientsDataSource extends BaseDataSource {
  constructor(private store: Store<AppState>) {
    super();

    this.loading$ = this.store.pipe(select(selectClientsPageLoading));

    this.isPreloadTextViewed$ = this.store.pipe(select(selectClientsShowInitWaitingMessage));

    this.store.pipe(select(selectClientsInStore)).subscribe((response: QueryResultsModel) => {
      this.paginatorTotalSubject.next(response.totalCount);
      this.entitySubject.next(response.items);
    });
  }
}
