import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import moment from 'moment';
import { SubscriptionService, SubscriptionModel } from '../../../../../../core/saas';
import { TranslateService } from '@ngx-translate/core';
import { ConfigService } from '../../../../../../../app/config.service';

@Component({
  selector: 'kt-subscriptions-history',
  templateUrl: './subscriptions-history.dialog.component.html',
  styleUrls: ['./subscriptions-history.dialog.component.scss']
})
export class SubscriptionsHistoryDialogComponent implements OnInit {
  subscription: SubscriptionModel;
  displayedColumns: string[] = ['roleCode', 'subscriptionStartTime', 'subscriptionEndTime'];

  typeMapping = {}; // this is for mapping roleCode to the readable role name

  dataSource = [];

  isLoading= true;

  constructor(
    private dialogRef: MatDialogRef<SubscriptionsHistoryDialogComponent>,
    private subscriptionService: SubscriptionService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.subscription = this.data;
    this.getSubscriptionTypes(this.subscription.saasModuleId);
    this.getSubscriptionHistory(this.subscription.clientId, this.subscription.saasModuleId);
  }

  getSubscriptionHistory(clientId: number, productId: number) {
    this.isLoading = true;
    this.subscriptionService.loadSubscriptionHistory({clientId, productId}).subscribe(res => {
      if (res && Array.isArray(res)) {
        this.dataSource = this.dataSource.concat(res);
      }
      this.isLoading = false;
    });
  }

  getSubscriptionTypes(productCode: number) {
    if (productCode) {
      this.subscriptionService.loadAllSubscriptionTypes(productCode).subscribe(res => {
        for (const ele of res.data) {
          this.typeMapping[ele.roleCode] = ele.roleName;
        }
      });
    }
  }
}
