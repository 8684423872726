// Angular
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// RxJS
import { Observable, forkJoin, of } from 'rxjs';
import { mergeMap, delay, map } from 'rxjs/operators';
// Lodash
import { each } from 'lodash';
// CRUD
import { HttpUtilsService, QueryParamsModel, QueryResultsModel } from '../../_base/crud';
// Models
import { ClientModel } from '../_models/client.model';
import { ConfigService } from '../../../../app/config.service';
const API_CLIENTS_URL = 'api/clients';

//const BACKEND_URL = 'http://localhost:8095';

//US TEST
//const BACKEND_URL = 'http://ehs-dev.usequantum.com';

//US PRD
// const BACKEND_URL = 'http://ehs.usequantum.com';

//CN TEST
//const BACKEND_URL = 'http://ehs-dev.usequantum.com.cn';

//CN PRD
//const BACKEND_URL = 'http://saas.usequantum.com.cn';

// Fake REST API (Mock)
// This code emulates server calls
@Injectable()
export class ClientsService {
  BACKEND_URL = this.configService.SAAS_DOMAIN;
  constructor(private http: HttpClient, private httpUtils: HttpUtilsService, private configService: ConfigService) {}

  // CREATE =>  POST: add a new client to the server
  createClient(client: ClientModel) {
    // Note: Add headers if needed (tokens/bearer)
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    return this.http.post(this.BACKEND_URL + '/system/clientUserCreateOrUpdate', client, { headers: httpHeaders, responseType: 'text' });
  }

  isCompanyIdDuplicate(companyId: string, ruleOutClientId: number) {
    // Note: Add headers if needed (tokens/bearer)
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    const params = {
      companyId,
      ruleOutClientId,
    };
    return this.http.post(this.BACKEND_URL + '/system/isCompanyIdDuplicate', params, { headers: httpHeaders, responseType: 'text' });
  }

  trialSubscribe(client: ClientModel, productId: number) {
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    const params = {
      ...client,
      productId,
    };
    return this.http.post(this.BACKEND_URL + '/system/trial', params, { headers: httpHeaders, responseType: 'text' });
  }

  checkCompanyIdEqual(client: ClientModel) {
    // Note: Add headers if needed (tokens/bearer)
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    return this.http.post(this.BACKEND_URL + '/system/checkCompanyIdEqual', client, { headers: httpHeaders, responseType: 'text' });
  }

  checkUserSubscription(userId: string, productName: string) {
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    let params = {
      userId: userId,
      productName: productName,
    };
    return this.http.post(this.BACKEND_URL + '/system/checkUserProduct', params, { headers: httpHeaders, responseType: 'text' });
  }

  // READ
  getAllClients(): Observable<ClientModel[]> {
    return this.http.get<ClientModel[]>(API_CLIENTS_URL);
  }

  getClientById(clientId: number): Observable<ClientModel> {
    return this.http.get<ClientModel>(API_CLIENTS_URL + `/${clientId}`);
  }

  // Method from server should return QueryResultsModel(items: any[], totalsCount: number)
  // items => filtered/sorted result
  findAllCompanyAdmins(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
    // This code imitates server calls
    return this.http.get<ClientModel[]>(this.BACKEND_URL + '/system/clientUserList').pipe(
      // map((res) =>
      //   res.map((item) => {
      //     return {
      //       ...item,
      //       products: item.products.join('@'),
      //     };
      //   })
      // ),
      mergeMap((res) => {
        const result = this.httpUtils.baseFilter(res, queryParams);
        // const result = this.httpUtils.baseFilter(res, queryParams, ['status', 'products']);
        return of(result);
      }),
      mergeMap((result) => {
        const newItems = result.items.map((item) => {
          return {
            ...item,
            // products: item.products.split('@'),
          };
        });
        const newRes = new QueryResultsModel(newItems, result.totalCount, result.errorMessage);
        return of(newRes);
      })
    );
  }

  loadAllProducts(): any {
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    return this.http.post(this.BACKEND_URL + '/system/loadallproducts', { headers: httpHeaders, responseType: 'text' });
  }

  updateProduct(client: ClientModel): Observable<any> {
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    return this.http.post(this.BACKEND_URL + '/system/updateproduct', client, { headers: httpHeaders, responseType: 'text' });
  }

  deactivateClient(client: ClientModel): Observable<any> {
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    return this.http.post(this.BACKEND_URL + '/system/clientdeactivate', client, { headers: httpHeaders, responseType: 'text' });
  }

  // UPDATE => PUT: update the client on the server
  updateClient(client: ClientModel): Observable<any> {
    const httpHeader = this.httpUtils.getHTTPHeaders();
    return this.http.post(this.BACKEND_URL + '/system/updateclientsubscription', client, { headers: httpHeader, responseType: 'text' });
  }

  getOneClient(client: ClientModel): Observable<any> {
    const httpHeader = this.httpUtils.getHTTPHeaders();
    return this.http.post(this.BACKEND_URL + '/system/getoneclient', client, { headers: httpHeader }).pipe(
      map((res) => {
        return res;
      })
    );
  }

  // UPDATE Status
  // updateStatusForClient(clients: ClientModel[], status: number): Observable<any> {
  //   const tasks$ = [];
  //   each(clients, (element) => {
  //     const _client = Object.assign({}, element);
  //     _client.status = status;
  //     tasks$.push(this.updateClient(_client));
  //   });
  //   return forkJoin(tasks$);
  // }

  // DELETE => delete the client from the server
  deleteClient(clientId: number): Observable<any> {
    const url = `${API_CLIENTS_URL}/${clientId}`;
    return this.http.delete<ClientModel>(url);
  }

  deleteClients(ids: number[] = []): Observable<any> {
    const tasks$ = [];
    const length = ids.length;
    // tslint:disable-next-line:prefer-const
    for (let i = 0; i < length; i++) {
      tasks$.push(this.deleteClient(ids[i]));
    }
    return forkJoin(tasks$);
  }
}
