// Angular
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// RxJS
import { Observable, of, forkJoin } from 'rxjs';
import { map, catchError, mergeMap, tap } from 'rxjs/operators';

// CRUD
import { QueryParamsModel, QueryResultsModel, HttpUtilsService } from '../../_base/crud';
// Models
import { User } from '../_models/user.model';
import { authorizeUser } from '../_models/authorizeUser.model';
import { ConfigService } from '../../../../app/config.service';


//const BACKEND_URL = 'http://localhost:8095';

//US TEST
//const BACKEND_URL = 'http://ehs-dev.usequantum.com';

//US PRD
// const BACKEND_URL = 'http://ehs.usequantum.com';

//CN TEST
//const BACKEND_URL = 'http://ehs-dev.usequantum.com.cn';

//CN PRD
//const BACKEND_URL = 'http://saas.usequantum.com.cn';



const V12_URL = 'http://v12s26.qehs-qa.usequantum.com/facts_backend-2.6/rest';



@Injectable()
export class LoginAuthService {
    constructor(
        private http: HttpClient,
        private httpUtils: HttpUtilsService,
        private configService: ConfigService
    ) { }

    BACKEND_URL = this.configService.SAAS_DOMAIN;

    login(email: string, password: string) {
        if (!email || !password) {
            return of(null);
        }
        let params = {
            "username": email,
            "password": password
        };
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
        return this.http.post<authorizeUser>(this.BACKEND_URL + "/system/authorize", params);
    }
    loginV12(email: string, password: string) {
        if (!email || !password) {
            return of(null);
        }
        let params = {
            "userName": email,
            "password": password
        };
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
        return this.http.post(V12_URL + '/permissions/login', params);
    }



}
