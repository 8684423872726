import { QueryParamsModel } from '../../_base/crud/models/query-models/query-params.model';
import { forkJoin } from 'rxjs';
// Angular
import { Injectable } from '@angular/core';
// RxJS
import { mergeMap, map, tap, delay } from 'rxjs/operators';
// NGRX
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
// CRUD
import { QueryResultsModel } from '../../_base/crud';
// Services
import { CompanyUsersService } from '../_services';
// State
import { AppState } from '../../reducers';
// Actions
import {
  CompanyUsersPageToggleLoading,
  CompanyUserActionToggleLoading,
  CompanyUserActionTypes,
  CompanyUsersPageRequested,
  CompanyUsersPageLoaded,
  CompanyUserOnServerCreated,
  CompanyUserCreated,
} from '../_actions/companyUser.actions';
import { of } from 'rxjs';
import { Update } from '@ngrx/entity';
// import { companyUserModel } from '..';

@Injectable()
export class CompanyUserEffects {
  showPageLoadingDistpatcher = new CompanyUsersPageToggleLoading({ isLoading: true });
  showActionLoadingDistpatcher = new CompanyUserActionToggleLoading({ isLoading: true });
  hideActionLoadingDistpatcher = new CompanyUserActionToggleLoading({ isLoading: false });

  @Effect()
  loadcompanyUsersPage$ = this.actions$.pipe(
    ofType<CompanyUsersPageRequested>(CompanyUserActionTypes.CompanyUsersPageRequested),
    mergeMap(({ payload }) => {
      this.store.dispatch(this.showPageLoadingDistpatcher);
      const requestToServer = this.companyUsersService.loadAllUsers(payload.page);
      const lastQuery = of(payload.page);
      return forkJoin(requestToServer, lastQuery);
    }),
    map((response) => {
      console.log(response);
      const result: QueryResultsModel = response[0];
      const lastQuery: QueryParamsModel = response[1];
      const pageLoadedDispatch = new CompanyUsersPageLoaded({
        companyUsers: result.items,
        totalCount: result.totalCount,
        page: lastQuery,
      });
      return pageLoadedDispatch;
    })
  );

  @Effect()
  createcompanyUser$ = this.actions$.pipe(
    ofType<CompanyUserOnServerCreated>(CompanyUserActionTypes.CompanyUserOnServerCreated),
    mergeMap(({ payload }) => {
      this.store.dispatch(this.showActionLoadingDistpatcher);
      return this.companyUsersService.createOrUpdateCompanyUser(payload.companyUser).pipe(
        tap(() => {
          //   this.store.dispatch(new CompanyUserCreated({ companyUser: payload.companyUser }));
        })
      );
    }),
    map(() => {
      return this.hideActionLoadingDistpatcher;
    })
  );

  constructor(private actions$: Actions, private companyUsersService: CompanyUsersService, private store: Store<AppState>) {}
}
