import { Component } from "@angular/core";

@Component({
  selector: "activated",
  templateUrl: "./activated.component.html",
  styleUrls: ["./activated.component.scss"],
})
export class ActivatedComponent {
  public companyId: string;
  public companySite: string;
  public companySiteEmployeeUrl: string;
  constructor() {
  }
  ngOnInit() {
    // localStorage.setItem("companyId",'quantum');
    this.companyId = localStorage.getItem("companyId");
    this.companySite = localStorage.getItem("companyId") + '.ehs.usequantum.com';
    this.companySiteEmployeeUrl= this.companyId+ '.ehs.usequantum.com/web/quantum/ehs/system-setting/employee/list';
  }
}