// USA
export const locale = {
  lang: 'en',
  data: {
    PAGINATOR: {
      ITEMS_PER_PAGE: 'Items per page',
      OF: 'of',
    },
    TRANSLATOR: {
      SELECT: 'Select your language',
    },
    BUTTON: {
      CANCEL: 'Cancel',
      CONFIRM: 'Confirm',
      SAVE: 'Save',
      REINVITE: 'Reinvite',
    },
    LANGUAGE: {
      ENGLISH: 'English',
      SIMPLIFIED_CHINESE: 'Simplified Chinese',
      TRADITIONAL_CHINESE: 'Traditional Chinese',
    },
    PRODUCT: {
      SAFETY_OBSERVATION: 'Safety Observation',
      Chemical_Management: 'Chemical Management',
      COVID_CHECK: 'COVID Check',
      REGULATION: 'Regulation',
      TRAINING: 'Training',
      RISK: 'Risk',
      INCIDENT: 'Incident',
    },
    ERROR: {
      FORM_FIELD_INVALID_ALERT: 'Change a few things up and try submitting again.',
      IS_REQUIRED: 'is empty or wrong format',
      COMPANY_ID_DUPLICATE: 'Company ID already exists',
    },
    SEARCH: {
      ALL_SEARCH: 'Search All',
      ALL: 'All',
      SUSPEND: 'Suspend',
      ACTIVE: 'Active',
      PENDING: 'Pending',
      INACTIVE: 'Inactive',
      REGULATION: 'Regulation',
      SEARCH: 'Search',
      FILTER_BY_STATUS: 'Filter by Status',
      FILTER_BY_TYPE: 'Filter by Type',
      FILTER_BY_PRODUCT: 'Filter by Product',
      SEARCH_IN_ALL_FIELD: 'Search in all fields',
    },
    MENU: {
      NEW: 'new',
      ACTIONS: 'Actions',
      CREATE_POST: 'Create New Post',
      PAGES: 'Pages',
      FEATURES: 'Features',
      APPS: 'Apps',
      DASHBOARD: 'Dashboard',
      LAYOUTBUILDER: 'Layout Builder',
      CLIENTS: 'Clients',
      USERS: 'Users',
      SUBSCRIPTIONS: 'Subscriptions',
      ALL_APPS: 'All Apps',
    },
    AUTH: {
      GENERAL: {
        OR: 'Or',
        SUBMIT_BUTTON: 'Submit',
        NO_ACCOUNT: "Don't have an account?",
        SIGNUP_BUTTON: 'Sign Up',
        FORGOT_BUTTON: 'Forgot Password',
        BACK_BUTTON: 'Back',
        PRIVACY: 'Privacy',
        LEGAL: 'Legal',
        CONTACT: 'Contact',
      },
      LOGIN: {
        TITLE: 'Login Account',
        BUTTON: 'Sign In',
      },
      FORGOT: {
        TITLE: 'Forgotten Password?',
        DESC: 'Enter your email to reset your password',
        SUCCESS: 'Your password has been successfully saved. Loading to your product...',
      },
      REGISTER: {
        TITLE: 'Sign Up',
        DESC: 'Enter your details to create your account',
        SUCCESS: 'Your account has been successfully registered.',
      },
      INPUT: {
        EMAIL: 'Email',
        FULLNAME: 'Fullname',
        PASSWORD: 'Password',
        CONFIRM_PASSWORD: 'Confirm Password',
        USERNAME: 'Username',
        IAGREE: 'I agree',
        TERMS: 'terms',
        AND: '&',
        CONDITIONS: 'conditions',
        NOACCOUNT: 'Dont have an account yet?',
      },
      RESET: {
        TITLE: 'Start your account today',
        PASSWORD: 'Password',
        CONFIRM_PASSWORD: 'Confirm Password',
        FIRST_NAME: 'First Name',
        LAST_NAME: 'Last Name',
      },
      VALIDATION: {
        INVALID: '{{name}} is not valid',
        REQUIRED: '{{name}} is required',
        MIN_LENGTH: '{{name}} minimum length is {{min}}',
        AGREEMENT_REQUIRED: 'Accepting terms & conditions are required',
        NOT_FOUND: 'The requested {{name}} is not found',
        INVALID_LOGIN: 'The login detail is incorrect',
        MAX_LENGTH_FIELD: 'Maximum field length:',
        INVALID_FIELD: 'Field is not valid',

        REQUIRED_FIELD: 'Required field',
        MIN_LENGTH_FIELD: 'Minimum field length:',
        PASSWORD_DOESNOT_MATCH: 'Passsword and Confirm Password does not match.',
      },
    },
    CLIENT: {
      TITLE: {
        NEW_CLIENT: 'New Client',
        CLIENTS: 'Clients',
      },
      PROFILE: {
        SIGNOUT: 'Sign out',
      },
      TABLE: {
        COMPANY_NAME: 'Company Name',
        LANGUAGE: 'Notification Language',
        COMPANY_ID: 'Company ID',
        ADMIN_USER: 'Admin User email',
        FIRST_NAME: 'First Name',
        LAST_NAME: 'Last Name',
        PHONE: 'Phone',
        JOB_TITLE: 'Job Title',
        PRODUCT: 'Product',
        STATUS: 'Status',
        ACTIONS: 'Actions',
        EMAIL: 'Email',
      },
      CONFIRMATION: {
        ACTIVATE:
          // tslint:disable-next-line: max-line-length
          'Are you sure to activate database for this company? Once the database is activated, all the information cannot be changed. Please make sure the information is correct.',
      },
    },
    COMPANY_USER: {
      DASHBOARD: {
        CHOOSE_PRODUCT: 'Choose your product',
      },
      TABLE: {
        COMPANY: 'Company',
        LOGIN_EMAIL: 'Login Email',
        ROLE: 'Role',
        STATUS: 'Status',
        ACTIONS: 'Actions',
        LANGUAGE: 'Notification Language',
        FIRST_NAME: 'First Name',
        LAST_NAME: 'Last Name',
        FULL_NAME: 'Full Name',
        PHONE: 'Phone',
        JOB_TITLE: 'Job Title',
        COMPANY_ADMIN: 'Admin',
        GENERAL_USER: 'General User',
      },
      TITLE: {
        NEW_USER: 'Add',
        USER_MANAGEMENT: 'User Management',
      },
      BUTTON: {
        CANCEL: 'Cancel',
        CONFIRM: 'Confirm',
        SAVE: 'Save',
        REINVITE: 'Reinvite',
      },
      ALERT: {
        CHANGE_ROLE: 'SUCCESS, logging out',
        CHANGE_ROLE_MESSAGE: 'Only one user will be company admin. Are you sure to continue?',
      },
    },
    SUBSCRIPTION: {
      SUBSCRIPTION: 'Subscription',
      TABLE: {
        CLIENT: 'Client',
        PRODUCT: 'Product',
        START_DATE: 'Start Date',
        PERIOD: 'Period',
        END_DATE: 'End Date',
        STATUS: 'Status',
        DAYS14: '14 days',
        MONTH1: '1 month',
        MONTH6: '6 months',
        DAYS: 'days',
        MONTHS: 'months',
        ADD_SUBSCRIPTION: 'Add Subscription',
        CLIENT_INFO: 'Client Information',
        SUBSCRIPTION_TYPE: 'Subscription Type',
        SUBSCRIPTION_INFO: 'Subscription Information',
        SUBSCRIPTION_HISTORY: 'Subscription History',
        RENEW_SUBSCRIPTION: 'Renew Subscription',
        CANCEL_SUBSCRIPTION: 'Unsubscribe',
        DATE: 'Date',
      },
      UNSUBSCRIBE: {
        TITLE: 'Cancel Subscription',
        DESCRIPTION: 'Are you sure you want to cancel the subscription?',
        CONFIRM_ACTION: 'Cancel',
        CANCEL_ACTION: 'Do Not Cancel',
        SUCCESS_MESSAGE: 'Unsubscribed!',
        FAIL_MESSAGE: 'Cannot unsubscribe!'
      },
      SYNC: {
        SUCCESS_MESSAGE: 'Synced Successfully !',
        FAIL_MESSAGE: 'Sync Failed !'
      },
      BUTTON: {
        ADD: 'Add',
        CLOSE: 'Close'
      },
      ALERT: {
        SUBSCRIPTION_DUPLICATE: 'The client already has the subscription of product that covers the period',
      },
    },
    ECOMMERCE: {
      COMMON: {
        SELECTED_RECORDS_COUNT: 'Selected records count: ',
        ALL: 'All',
        SUSPENDED: 'Suspended',
        ACTIVE: 'Active',
        FILTER: 'Filter',
        BY_STATUS: 'by Status',
        BY_TYPE: 'by Type',
        BUSINESS: 'Business',
        INDIVIDUAL: 'Individual',
        SEARCH: 'Search',
        IN_ALL_FIELDS: 'in all fields',
      },
      ECOMMERCE: 'eCommerce',
      CUSTOMERS: {
        CUSTOMERS: 'Customers',
        CUSTOMERS_LIST: 'Customers list',
        NEW_CUSTOMER: 'New Customer',
        DELETE_CUSTOMER_SIMPLE: {
          TITLE: 'Customer Delete',
          DESCRIPTION: 'Are you sure to permanently delete this customer?',
          WAIT_DESCRIPTION: 'Customer is deleting...',
          MESSAGE: 'Customer has been deleted',
        },
        DELETE_CUSTOMER_MULTY: {
          TITLE: 'Customers Delete',
          DESCRIPTION: 'Are you sure to permanently delete selected customers?',
          WAIT_DESCRIPTION: 'Customers are deleting...',
          MESSAGE: 'Selected customers have been deleted',
        },
        UPDATE_STATUS: {
          TITLE: 'Status has been updated for selected customers',
          MESSAGE: 'Selected customers status have successfully been updated',
        },
        EDIT: {
          UPDATE_MESSAGE: 'Customer has been updated',
          ADD_MESSAGE: 'Customer has been created',
        },
      },
    },
    REGISTER: {
      HI: 'Hi',
      MESSAGE: 'you’re one step away…',
      TITLE: 'Start your account today',
      PASSWORD: 'Password',
      CONFIRM_PASSWORD: 'Confirm Password',
      SUBMIT_BUTTON: 'Submit',
    },
    TRIAL_SIGNUP: {
      TITLE: 'Try Quantum For 14 Days',
      COMPANY_NAME: 'Company Name',
      FIRST_NAME: 'First Name',
      LAST_NAME: 'Last Name',
      PHONE: 'Phone',
      JOB_TITLE: 'Job Title',
      EMAIL: 'Email',
      PRODUCT: 'Product',
      IAGREE: 'I agree',
      TERMS_AND_CONDITIONS: 'the terms and conditions',
      MUSTACCEPT: 'You must accept the terms and conditions',
      SUBMIT: 'Submit',
      CANCEL: 'Cancel',
      SUCCESS: 'You has submitted the form successfully. We will contact you soon. Thanks for your patience.',
    },
  },
};
