// Angular
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// RxJS
import { Observable, forkJoin, of } from 'rxjs';
import { mergeMap, delay, map } from 'rxjs/operators';
// Lodash
import { each } from 'lodash';
// CRUD
import { HttpUtilsService, QueryParamsModel, QueryResultsModel } from '../../_base/crud';
// Models
import { CompanyUserModel } from '../_models/companyUser.model';
import { Store } from '@ngrx/store';
import { ConfigService } from '../../../../app/config.service';

const API_CLIENTS_URL = 'api/users';

//const BACKEND_URL = 'http://localhost:8095';

//US TEST
//const BACKEND_URL = 'http://ehs-dev.usequantum.com';

//US PRD
// const BACKEND_URL = 'http://ehs.usequantum.com';

//CN TEST
//const BACKEND_URL = 'http://ehs-dev.usequantum.com.cn';

//CN PRD
//const BACKEND_URL = 'http://saas.usequantum.com.cn';

// Fake REST API (Mock)
// This code emulates server calls
@Injectable()
export class CompanyUsersService {
  BACKEND_URL = this.configService.SAAS_DOMAIN;
  constructor(
    private http: HttpClient,
    private httpUtils: HttpUtilsService,
    private store: Store<any>,
    private configService: ConfigService
  ) {}

  // CREATE =>  POST: add a new companyUser to the server
  createOrUpdateCompanyUser(companyUser: CompanyUserModel) {
    // Note: Add headers if needed (tokens/bearer)
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    return this.http.post(this.BACKEND_URL + '/system/createOrUpdateCompanyUser', companyUser, {
      headers: httpHeaders,
      responseType: 'text',
    });
  }

  checkCompanyIdDuplicate(companyId: string) {
    // Note: Add headers if needed (tokens/bearer)
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    let params = {
      companyId: companyId,
    };
    return this.http.post(this.BACKEND_URL + '/system/checkCompanyIdDuplicate', params, { headers: httpHeaders, responseType: 'text' });
  }

  checkCompanyIdEqual(companyUser: CompanyUserModel) {
    // Note: Add headers if needed (tokens/bearer)
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    return this.http.post(this.BACKEND_URL + '/system/checkCompanyIdEqual', companyUser, { headers: httpHeaders, responseType: 'text' });
  }

  // READ
  getAllcompanyUsers(): Observable<CompanyUserModel[]> {
    return this.http.get<CompanyUserModel[]>(API_CLIENTS_URL);
  }

  getcompanyUserById(companyUserId: number): Observable<CompanyUserModel> {
    return this.http.get<CompanyUserModel>(API_CLIENTS_URL + `/${companyUserId}`);
  }

  // Method from server should return QueryResultsModel(items: any[], totalsCount: number)
  // items => filtered/sorted result
  findcompanyUsers(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
    // This code imitates server calls
    const url = API_CLIENTS_URL;
    let clientId = 0;
    // this.store.subscribe((state) => (clientId = state.auth.user.clientId));
    if (clientId === 1) {
      clientId = null;
    }
    let params = {
      clientId: clientId,
    };
    return this.http.post<CompanyUserModel[]>(this.BACKEND_URL + '/system/getAllUsers', params).pipe(
      mergeMap((res) => {
        const result = this.httpUtils.baseFilter(res, queryParams, ['status', 'product']);
        console.log(result);
        return of(result);
      })
    );
  }

  loadAllUsers(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
    // This code imitates server calls
    const url = API_CLIENTS_URL;
    let clientId = -1;
    this.store.subscribe((state) => {
      if (state.auth.user.userType !== 'QUANTUM_ADMIN') {
        clientId = state.auth.user.clientId;
      }
    });

    return this.http.get<CompanyUserModel[]>(this.BACKEND_URL + '/system/selectAllUsers?clientId=' + clientId).pipe(
      mergeMap((res) => {
        const result = this.httpUtils.baseFilter(res, queryParams);
        console.log(result);
        return of(result);
      })
    );
  }

  // UPDATE => PUT: update the companyUser on the server
  updatecompanyUser(companyUser: CompanyUserModel): Observable<any> {
    const httpHeader = this.httpUtils.getHTTPHeaders();
    return this.http.post(this.BACKEND_URL + '/system/updatecompanyUsersubscription', companyUser, {
      headers: httpHeader,
      responseType: 'text',
    });
  }

  // UPDATE => PUT: update the companyUser on the server
  exchangeRole(oldAdminUserId: number, oldGeneralUserId): Observable<any> {
    const httpHeader = this.httpUtils.getHTTPHeaders();
    const data = {
      companyAdminUserId: oldAdminUserId,
      generalUserUserId: oldGeneralUserId,
    };
    return this.http.post(this.BACKEND_URL + '/system/exchangeRole', data, {
      headers: httpHeader,
      responseType: 'text',
    });
  }

  getOnecompanyUser(companyUser: CompanyUserModel): Observable<any> {
    const httpHeader = this.httpUtils.getHTTPHeaders();
    return this.http.post(this.BACKEND_URL + '/system/getonecompanyUser', companyUser, { headers: httpHeader }).pipe(
      map((res) => {
        return res;
      })
    );
  }

  // UPDATE Status
  // updateStatusForcompanyUser(companyUsers: CompanyUserModel[], status: number): Observable<any> {
  //   const tasks$ = [];
  //   each(companyUsers, (element) => {
  //     const _companyUser = Object.assign({}, element);
  //     _companyUser.status = status;
  //     tasks$.push(this.updatecompanyUser(_companyUser));
  //   });
  //   return forkJoin(tasks$);
  // }

  // DELETE => delete the companyUser from the server
  deletecompanyUser(companyUserId: number): Observable<any> {
    const url = `${API_CLIENTS_URL}/${companyUserId}`;
    return this.http.delete<CompanyUserModel>(url);
  }

  deletecompanyUsers(ids: number[] = []): Observable<any> {
    const tasks$ = [];
    const length = ids.length;
    // tslint:disable-next-line:prefer-const
    for (let i = 0; i < length; i++) {
      tasks$.push(this.deletecompanyUser(ids[i]));
    }
    return forkJoin(tasks$);
  }
}
