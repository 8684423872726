// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  isMockEnabled: true, // You have to switch this, when your real back-end is done
  authTokenKey: 'authce9d77b308c149d5992a80073637e4d5',
  v7DbPrefix: '',
  // saasDomain: 'http://localhost:8095',
  // saasDomain: 'http://ehs-dev.usequantum.com',
  // saasDomain: 'http://saas.usequantum.com.cn',
  saasDomain: 'https://saas-qa-manager.usequantum.com',
  // saasDomain: 'http://13.231.110.75',
  //saasDomain: 'http://saas.rking.com',
  v12LoginUrl: '.saas-dev-app.logicsolutions.com.tw/v12/#/login',
  v13LoginUrl: '.qa.usequantum.com/web/auth/login',
  safetyObservationUrl: '.qehs-qa.usequantum.com/V7/#/auth/login?',
  sdsUrl: '.qehs-qa.usequantum.com/#/login?',
  covidUrl: '.qehs-qa.usequantum.com/#/login?',
  regulationUrl: '.saas-dev-app.logicsolutions.com.tw/regulation/#/auth/login?',
  trainingUrl: '.saas-dev-app.logicsolutions.com.tw/training/#/auth/login?',
  riskUrl: '.saas-dev-app.logicsolutions.com.tw/risk/#/auth/login?',
  incidentUrl: '.saas-dev-app.logicsolutions.com.tw/incident/#/auth/login?',
  http: 'http://',
  trialCancelButtonUrlSafetyObservation_English:
    'https://www.usequantum.com/qsafety/safety-observation-software/',
  trialCancelButtonUrlSafetyObservation_TraditionalChinese:
    'https://tw.usequantum.com/ehs-software/safety-observation/',
  trialCancelButtonUrlSafetyObservation_SimplifiedChinese: '',
  trialCancelButtonUrlSDS_English:
    'https://www.usequantum.com/chemical-management-solution/sds-management-software/',
  trialCancelButtonUrlSDS_TraditionalChinese:
    'https://tw.usequantum.com/ehs-software/sds-management/',
  trialCancelButtonUrlSDS_SimplifiedChinese:
    'https://sds.usequantum.com.cn/sds-guanliruanjian/',
  trialCancelButtonUrlCovidCheck_English: '',
  trialCancelButtonUrlCovidCheck_TraditionalChinese: '',
  trialCancelButtonUrlCovidCheck_SimplifiedChinese: '',
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
