// NGRX
import { createFeatureSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter, Update } from '@ngrx/entity';
// Actions
import { SubscriptionActions, SubscriptionActionTypes } from '../_actions/subscription.actions';
// Models
import { SubscriptionModel } from '../_models/subscription.model';
import { QueryParamsModel } from '../../_base/crud';

export interface SubscriptionsState extends EntityState<SubscriptionModel> {
  listLoading: boolean;
  actionsloading: boolean;
  totalCount: number;
  lastCreatedsubscriptionId: number;
  lastQuery: QueryParamsModel;
  showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<SubscriptionModel> = createEntityAdapter<SubscriptionModel>();

export const initialsubscriptionsState: SubscriptionsState = adapter.getInitialState({
  subscriptionForEdit: null,
  listLoading: false,
  actionsloading: false,
  totalCount: 0,
  lastCreatedsubscriptionId: undefined,
  lastQuery: new QueryParamsModel({}),
  showInitWaitingMessage: true,
});

export function subscriptionsReducer(state = initialsubscriptionsState, action: SubscriptionActions): SubscriptionsState {
  switch (action.type) {
    case SubscriptionActionTypes.SubscriptionPageToggleLoading: {
      return {
        ...state,
        listLoading: action.payload.isLoading,
        lastCreatedsubscriptionId: undefined,
      };
    }
    case SubscriptionActionTypes.SubscriptionActionToggleLoading: {
      return {
        ...state,
        actionsloading: action.payload.isLoading,
      };
    }
    case SubscriptionActionTypes.SubscriptionOnServerCreated:
      return {
        ...state,
      };
    case SubscriptionActionTypes.SubscriptionPageLoaded: {
      return adapter.addMany(action.payload.subscription, {
        ...initialsubscriptionsState,
        totalCount: action.payload.totalCount,
        listLoading: false,
        lastQuery: action.payload.page,
        showInitWaitingMessage: false,
      });
    }
    default:
      return state;
  }
}

export const getsubscriptionState = createFeatureSelector<SubscriptionModel>('subscriptions');

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
