import { AbstractControl, ValidationErrors } from '@angular/forms';
import { map } from 'rxjs/operators';
import { ClientsService } from '../../../../../../../../src/app/core/saas/_services/clients.service.fake';

export class CompanyIdValidator {
  static clientsService: any;
  /**
   * Check matching password with confirm password
   * @param control AbstractControl
   */

  constructor(private clientsService: ClientsService) {
    this.clientsService = clientsService;
  }

  static MatchPassword(control: AbstractControl) {
    const companyId = control.get('companyId').value;

    this.clientsService
      .checkCompanyIdDuplicate(companyId)
      .pipe(
        map((data) => {
          if (data == 'SUCCESS') {
            //duplicate company id
            console.log('duplicate');
            control.get('companyId').setErrors({ DuplicateCompanyId: true });
          } else {
            return null;
          }
        })
      )
      .subscribe();
  }

  static cannotContainSpace(control: AbstractControl): ValidationErrors | null {
    if ((control.value as string).indexOf(' ') >= 0) {
      return { cannotContainSpace: true };
    }

    return null;
  }
}
