// Angular
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

// Translate Module
import { TranslateService } from '@ngx-translate/core';

// Interface
interface SimpleDialogData {
  titleTranslationCode?: string;
  messageTranslationCode: string;
  cancelTextTranslationCode?: string;
  actionTextTranslationCode?: string;
  hideActions: boolean;
  cancelClass?: string;
  actionClass?: string;
  reverseActionButtonOrder?: boolean;
}

@Component({
  selector: 'kt-simple-dialog',
  templateUrl: './simple-dialog.component.html',
  styleUrls: ['./simple-dialog.component.scss']
})
export class SimpleDialogComponent implements OnInit {
  viewLoading = false;

  title: string;
  message: string;
  waitDescription: string;
  cancelText: string;
  actionText: string;

  constructor(
    private dialogRef: MatDialogRef<SimpleDialogComponent>,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: SimpleDialogData
  ) { }

  ngOnInit() {
    // Init
    this.title = '';
    this.message = '';
    this.waitDescription = '';

    // Translate
    this.title = this.translate.instant(this.data.titleTranslationCode) || '';
    this.message = this.translate.instant(this.data.messageTranslationCode) || '';

    this.cancelText = this.translate.instant(this.data.cancelTextTranslationCode || 'BUTTON.CANCEL');
    this.actionText = this.translate.instant(this.data.actionTextTranslationCode || 'BUTTON.CONFIRM');
  }

  onNoClick(): void {
    this.dialogRef.close('no');
  }

  onYesClick(): void {
    this.dialogRef.close('yes');
  }
}
