// Foundation
import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'

// RxJs
import { Observable, Subject } from 'rxjs'
import { finalize, takeUntil, tap, map } from 'rxjs/operators'

// Service
import { TranslateService } from '@ngx-translate/core'
import { AuthNoticeService, AuthService, Login } from '../../../../core/auth'
import { LoginAuthService } from '../../../../core/saas/_services/auth.service'
import { ConfigService } from '../../../../../app/config.service'

// NGRX
import { Store } from '@ngrx/store'
import { AppState } from '../../../../core/reducers'
import * as authActions from '../../../../core/saas/_actions/auth.actions'

const DEMO_PARAMS = {
  EMAIL: '',
  PASSWORD: '',
}

@Component({
  selector: 'kt-login',
  templateUrl: './login.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class LoginComponent implements OnInit, OnDestroy {
  // Public params
  loginForm: FormGroup
  loading = false
  isLoggedIn$: Observable<boolean>
  errors: any = []

  private unsubscribe: Subject<any>

  private returnUrl: any

  constructor(
    private router: Router,
    private auth: AuthService,
    private authNoticeService: AuthNoticeService,
    private translate: TranslateService,
    private store: Store<AppState>,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private authLogin: LoginAuthService,
    private configService: ConfigService
  ) {
    this.unsubscribe = new Subject()
  }

  // Life Cycles
  // ------------------------------------------------------------------------------------

  ngOnInit(): void {
    this.initLoginForm()

    // redirect back to the returnUrl before login
    this.route.queryParams.subscribe((params) => {
      this.returnUrl = params.returnUrl || '/'
    })
  }

  ngOnDestroy(): void {
    this.authNoticeService.setNotice(null)
    this.unsubscribe.next()
    this.unsubscribe.complete()
    this.loading = false
  }

  // Public Methods
  // ------------------------------------------------------------------------------------

  initLoginForm() {
    this.loginForm = this.fb.group({
      email: [
        DEMO_PARAMS.EMAIL,
        Validators.compose([
          Validators.required,
          Validators.email,
          Validators.minLength(3),
          Validators.maxLength(320),
        ]),
      ],
      password: [
        DEMO_PARAMS.PASSWORD,
        Validators.compose([
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(100),
        ]),
      ],
    })
  }

  submit() {
    const controls = this.loginForm.controls
    /** check form */
    if (this.loginForm.invalid) {
      Object.keys(controls).forEach((controlName) =>
        controls[controlName].markAsTouched()
      )
      return
    }

    this.loading = true

    const authData = {
      email: controls.email.value,
      password: controls.password.value,
    }

    this.authLogin
      .login(authData.email, authData.password)
      .pipe(
        map((data) => {
          console.log('data: ', data)
          if (data != null) {
            this.store.dispatch(new authActions.LoginSuccess(data))
            console.log('data.user = ', data.user)
            if (data.userType === 'QUANTUM_ADMIN') {
              console.log(0)
              // TODO 修改为
              this.router.navigateByUrl('/clients')
              // this.router.navigateByUrl('/dashboard-company-admin')
            } else if (data.userType === 'COMPANY_ADMIN') {
              console.log(1)
              this.router.navigateByUrl('/dashboard-company-admin')
            } else {
              console.log(2)
              this.router.navigateByUrl('/dashboard-company-admin')
            }
          } else {
            this.authNoticeService.setNotice(
              this.translate.instant('AUTH.VALIDATION.INVALID_LOGIN'),
              'danger'
            )
          }
        }),
        takeUntil(this.unsubscribe),
        finalize(() => {
          this.loading = false
          this.cdr.markForCheck()
        })
      )
      .subscribe()
  }

  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.loginForm.controls[controlName]
    if (!control) {
      return false
    }

    const result =
      control.hasError(validationType) && (control.dirty || control.touched)
    return result
  }
}
