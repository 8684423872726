// Angular
import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
// Material
import { MatButtonModule, MatCheckboxModule, MatFormFieldModule, MatInputModule, MatSelectModule } from '@angular/material';
// Translate
import { TranslateModule } from '@ngx-translate/core';
// NGRX
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
// CRUD
import { InterceptService } from '../../../core/_base/crud/';
// Module components
import { AuthComponent } from './auth.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ActivateAccountComponent } from './activate-account/activate-account.component';
import { AuthNoticeComponent } from './auth-notice/auth-notice.component';
// Auth
import {
  AuthEffects,
  AuthGuard,
  AdminGuard,
  // authReducer,
  AuthService,
} from '../../../core/auth';

import { authReducer } from '../../../core/saas/_reducers/auth.reducer';
import { CommunicationService } from './../../../../../src/app/core/auth/_services/communication.service';
import { LoginAuthService } from './../../../../../src/app/core/saas/_services/auth.service';
import { FreeTrialComponent } from './free-trial/free-trial.component';
import { ConfirmationComponent } from './confirmation/confirmation.component';
import { ActivatedComponent } from './activated/activated.component';

const routes: Routes = [
  {
    path: '',
    component: AuthComponent,
    children: [
      {
        path: '',
        redirectTo: 'reset-password',
        pathMatch: 'full',
      },
      {
        path: 'login',
        component: LoginComponent,
        data: { returnUrl: window.location.pathname },
      },
      {
        path: 'register',
        component: RegisterComponent,
      },
      {
        path: 'forgot-password',
        component: ForgotPasswordComponent,
      },
      {
        path: 'reset-password/:userId/:companyId/:isCompanyAdmin/:productType/:emailAddress/:userLanguage',
        component: ResetPasswordComponent,
      },
      {
        path: 'activate-account/:userId/:companyId/:isCompanyAdmin/:productType/:emailAddress/:userLanguage',
        component: ActivateAccountComponent,
      },
      {
        path: 'trial/:product/:language',
        component: FreeTrialComponent,
      },
      {
        path: 'confirmation',
        component: ConfirmationComponent,
      },
      {
        path: 'activated',
        component: ActivatedComponent,
      }
    ],
  },
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    RouterModule.forChild(routes),
    MatInputModule,
    MatFormFieldModule,
    MatCheckboxModule,
    TranslateModule.forChild(),
    // StoreModule.forFeature('auth', authReducer),
    StoreModule.forFeature('auth', authReducer),
    EffectsModule.forFeature([AuthEffects]),
    MatSelectModule,
  ],
  providers: [
    InterceptService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptService,
      multi: true,
    },
    CommunicationService,
    LoginAuthService,
  ],
  exports: [AuthComponent],
  declarations: [
    AuthComponent,
    LoginComponent,
    RegisterComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    ActivateAccountComponent,
    AuthNoticeComponent,
    FreeTrialComponent,
    ConfirmationComponent,
    ActivatedComponent,
  ],
})
export class AuthModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: AuthModule,
      providers: [AuthService, AuthGuard, AdminGuard],
    };
  }
}
