// Angular
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ClientModel, ClientsStatusUpdated, ClientOnServerCreated, ClientUpdated } from '../../../../../../../app/core/saas';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../../../../app/core/reducers';
import { Update } from '@ngrx/entity';

@Component({
  selector: 'kt-client-confirmation-dialog',
  templateUrl: './client-confirmation-dialog.component.html',
})
export class ClientConfirmationDialogComponent implements OnInit {
  // Public properties
  viewLoading = false;
  editedClient: ClientModel;
  /**
   * Component constructor
   *
   * @param dialogRef: MatDialogRef<DeleteEntityDialogComponent>
   * @param data: any
   */
  constructor(
    public dialogRef: MatDialogRef<ClientConfirmationDialogComponent>,
    private store: Store<AppState>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  /**
   * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
   */

  /**
   * On init
   */
  ngOnInit() {
    this.editedClient = this.data.editedClient;
  }

  /**
   * Close dialog with false result
   */
  onNoClick(): void {
    this.dialogRef.close();
  }

  /**
   * Close dialog with true result
   */
  onYesClick(): void {
    /* Server loading imitation. Remove this */
    this.viewLoading = true;
    setTimeout(() => {
      this.dialogRef.close(true); // Keep only this row
    }, 300);
    console.log('editedClient: ', this.editedClient);
    if (this.editedClient.id > 0) {
      this.editedClient.userStatus = 'COMPANY_ADMIN';
      const updateClient: Update<ClientModel> = {
        id: this.editedClient.id,
        changes: this.editedClient,
      };
      this.store.dispatch(
        new ClientUpdated({
          partialClient: updateClient,
          client: this.editedClient,
        })
      );
    } else {
      this.editedClient.userStatus = 'COMPANY_ADMIN';
      this.store.dispatch(new ClientOnServerCreated({ client: this.editedClient }));
    }
  }
}
