// Angular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
// Fake API Angular-in-memory
import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
// Translate Module
import { TranslateModule, TranslateService } from '@ngx-translate/core';
// NGRX
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
// UI
import { PartialsModule } from '../../../partials/partials.module';
// Core
import { FakeApiService } from '../../../../core/_base/layout';
// Auth
import { ModuleGuard } from '../../../../core/auth';
// Core => Services
import {
  clientsReducer,
  ClientEffects,
  ClientsService,
  companyUsersReducer,
  CompanyUserEffects,
  CompanyUsersService,
  subscriptionsReducer,
  SubscriptionEffects,
  SubscriptionService,
} from '../../../../core/saas';
// Core => Utils
import { HttpUtilsService, TypesUtilsService, InterceptService, LayoutUtilsService } from '../../../../core/_base/crud';
// Shared
import { ActionNotificationComponent, FetchEntityDialogComponent, UpdateStatusDialogComponent } from '../../../partials/content/crud';
// Components
import { SaasComponent } from './saas.component';
// Clients
import { ClientsListComponent } from './clients/clients-list/clients-list.component';
import { UsersListComponent } from './users/users-list/users-list.component';
import { ClientEditDialogComponent } from './clients/client-edit/client-edit.dialog.component';

import { ClientConfirmationDialogComponent } from './clients/client-confirmation-dialog/client-confirmation-dialog.component';
import { ConfirmationDialogComponent } from './shared/confirmation-dialog/confirmation-dialog.component';
// Material

import {
  MatInputModule,
  MatPaginatorModule,
  MatProgressSpinnerModule,
  MatSortModule,
  MatTableModule,
  MatSelectModule,
  MatMenuModule,
  MatProgressBarModule,
  MatButtonModule,
  MatCheckboxModule,
  MatDividerModule,
  MatDialogModule,
  MatTabsModule,
  MatNativeDateModule,
  MatCardModule,
  MatRadioModule,
  MatIconModule,
  MatDatepickerModule,
  MatAutocompleteModule,
  MAT_DIALOG_DEFAULT_OPTIONS,
  MatSnackBarModule,
  MatTooltipModule,
  MatPaginatorIntl,
  MatChipsModule,
} from '@angular/material';
import { environment } from '../../../../../environments/environment';
import { CoreModule } from '../../../../core/core.module';
import { NgbProgressbarModule, NgbProgressbarConfig } from '@ng-bootstrap/ng-bootstrap';
import { NgxPermissionsModule } from 'ngx-permissions';

import { PaginatorIntlService } from '../../../../core/saas/_services/paginatorIntl.service';
import { UserEditDialogComponent } from './users/users-edit/user-edit.dialog.component';
import { UserConfirmationDialogComponent } from './users/users-comfirmation-dialog/user-confirmation-dialog.component';
import { SubscriptionsListComponent } from './subscriptions/subscriptions-list/subscriptions-list.component';
import { SubscriptionsEditDialogComponent } from './subscriptions/subscriptions-edit/subscriptions-edit.dialog.component';
import { SubscriptionsAddDialogComponent } from './subscriptions/subscriptions-add/subscriptions-add.dialog.component';
import { SubscriptionsHistoryDialogComponent } from './subscriptions/subscriptions-history/subscriptions-history.dialog.component';
import { SimpleDialogComponent } from './shared/simple-dialog/simple-dialog.component';
// tslint:disable-next-line:class-name
const routes: Routes = [
  {
    path: '',
    component: SaasComponent,
    // canActivate: [ModuleGuard],
    // data: { moduleName: 'saas' },
    children: [
      {
        path: '',
        redirectTo: 'clients',
        pathMatch: 'full',
      },
      {
        path: 'clients',
        component: ClientsListComponent,
      },
    ],
  },
];

@NgModule({
  imports: [
    MatDialogModule,
    CommonModule,
    HttpClientModule,
    PartialsModule,
    NgxPermissionsModule.forChild(),
    RouterModule.forChild(routes),
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forChild(),
    MatButtonModule,
    MatMenuModule,
    MatSelectModule,
    MatInputModule,
    MatTableModule,
    MatAutocompleteModule,
    MatRadioModule,
    MatDividerModule,
    MatIconModule,
    MatNativeDateModule,
    MatProgressBarModule,
    MatDatepickerModule,
    MatCardModule,
    MatPaginatorModule,
    MatSortModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatTabsModule,
    MatTooltipModule,
    NgbProgressbarModule,
    MatChipsModule,
    environment.isMockEnabled
      ? HttpClientInMemoryWebApiModule.forFeature(FakeApiService, {
          passThruUnknownUrl: true,
          dataEncapsulation: false,
        })
      : [],
    StoreModule.forFeature('clients', clientsReducer),
    StoreModule.forFeature('companyUsers', companyUsersReducer),
    StoreModule.forFeature('subscriptions', subscriptionsReducer),
    EffectsModule.forFeature([ClientEffects]),
    EffectsModule.forFeature([CompanyUserEffects]),
    EffectsModule.forFeature([SubscriptionEffects]),
  ],
  providers: [
    ModuleGuard,
    InterceptService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptService,
      multi: true,
    },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {
        hasBackdrop: true,
        panelClass: 'kt-mat-dialog-container__wrapper',
        height: 'auto',
        width: '900px',
      },
    },
    TypesUtilsService,
    LayoutUtilsService,
    HttpUtilsService,
    ClientsService,
    CompanyUsersService,
    SubscriptionService,
    TypesUtilsService,
    LayoutUtilsService,
    {
      provide: MatPaginatorIntl,
      useFactory: (translate) => {
        const service = new PaginatorIntlService();
        service.injectTranslateService(translate);
        return service;
      },
      deps: [TranslateService],
    },
  ],
  entryComponents: [
    ActionNotificationComponent,
    ClientEditDialogComponent,
    UserEditDialogComponent,
    SubscriptionsEditDialogComponent,
    SubscriptionsAddDialogComponent,
    SubscriptionsHistoryDialogComponent,
    ClientConfirmationDialogComponent,
    UserConfirmationDialogComponent,
    FetchEntityDialogComponent,
    UpdateStatusDialogComponent,
    ConfirmationDialogComponent,
    SimpleDialogComponent,
  ],
  declarations: [
    SaasComponent,
    // Clients
    ClientsListComponent,
    UsersListComponent,
    ClientEditDialogComponent,
    UserEditDialogComponent,
    ClientConfirmationDialogComponent,
    UserConfirmationDialogComponent,
    SubscriptionsListComponent,
    SubscriptionsEditDialogComponent,
    ConfirmationDialogComponent,
    SubscriptionsAddDialogComponent,
    SubscriptionsHistoryDialogComponent,
    SimpleDialogComponent,
  ],
})
export class SaasModule {}
