import { Component, OnInit, Inject } from '@angular/core'
import { FormGroup, FormBuilder, Validators } from '@angular/forms'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material'
import moment from 'moment'
import {
  SubscriptionService,
  SubscriptionModel,
} from '../../../../../../core/saas'
import { TranslateService } from '@ngx-translate/core'
import { ConfigService } from '../../../../../../../app/config.service'

@Component({
  selector: 'kt-subscriptions-add',
  templateUrl: './subscriptions-add.dialog.component.html',
  styleUrls: ['./subscriptions-add.dialog.component.scss'],
})
export class SubscriptionsAddDialogComponent implements OnInit {
  period: number
  subscriptionDuplicate: boolean
  subscriptionForm: FormGroup
  clientList: object
  productList: object
  subTypeList: object = []
  startDate: any
  minStartDate = new Date()
  minEndDate = new Date(moment(new Date()).add(1, 'd').format('MM/DD/YYYY'))
  // period: string;
  clientId: number
  productId: number
  subscription: SubscriptionModel
  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<SubscriptionsAddDialogComponent>,
    private subscriptionService: SubscriptionService,
    private translate: TranslateService,
    private configService: ConfigService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.subscription = this.data
    console.log('subscription', this.subscription)
    this.createForm()
    this.subscriptionService.loadAllGeneralClients().subscribe((data) => {
      this.clientList = data
    })

    this.subscriptionService.loadAllProducts().subscribe((data) => {
      this.productList = data
      // tslint:disable-next-line: forin
      // for (const i in this.productList) {
      //   this.productList[
      //     i
      //   ].productName = this.configService.translateProductName(
      //     this.productList[i].productCode
      //   )
      // }
    })

    const dateTime = this.formatDate(new Date())
    this.showStartTime()
    this.subscriptionDuplicate = false
  }
  handlePeriod(e) {
    var endT = ''
    switch (e) {
      case 'D1':
        endT = this.getRecentDay(1)
        break
      case 'D14':
        endT = this.getRecentDay(14)
        break
      case 'M1':
        endT = this.getRecentMonth(1)
        break
      case 'M6':
        endT = this.getRecentMonth(6)
        break
    }
    // this.subscriptionForm.controls.subscriptionEndTime = 'x1'
    this.subscriptionForm.patchValue({ subscriptionEndTime: endT })
    console.log(this.subscriptionForm.controls.subscriptionEndTime.value)
  }
  showStartTime() {
    if (this.subscription === null) {
      this.subscriptionForm.controls.subscriptionStartTime.setValue(new Date())
      // this.subscriptionForm.controls.subscriptionStartTime.disable();
    }
  }
  // 近N月 - Moment.js
  getRecentMonth(n) {
    let startT = this.subscriptionForm.controls.subscriptionStartTime.value
    let month = moment(startT).add(n, 'months').format('YYYY-MM-DD')
    return month
  }
  // 近N天 -Moment.js
  getRecentDay(n) {
    let startT = this.subscriptionForm.controls.subscriptionStartTime.value
    let day = moment(startT).add(n, 'days').format('YYYY-MM-DD')
    return day
  }

  enableDatePicker(): boolean {
    if (
      this.subscription !== null &&
      this.subscription.subscriptionStatus === 'PENDING'
    ) {
      return true
    }
    return false
  }

  createForm() {
    const subscription = this.subscription
    if (subscription === null) {
      this.subscriptionForm = this.fb.group({
        companyName: ['', Validators.required],
        productCode: ['', Validators.required],
        subscriptionType: [{ value: '', disabled: true }],
        // subscriptionType: [{value: '', disabled: true}, Validators.required],
        subscriptionStartTime: ['', Validators.required],
        subscriptionEndTime: ['', Validators.required],
      })
    }
  }

  prepareData() {
    let isSubscribeNow = 'false'
    if (
      this.isToday(
        new Date(this.subscriptionForm.controls.subscriptionStartTime.value)
      )
    ) {
      isSubscribeNow = 'true'
    }
    const data = {
      id: this.subscription === null ? null : this.subscription.subscriptionId,
      clientId: this.subscriptionForm.controls.companyName.value,
      subscriptionStartTime: this.formatDate(
        this.subscriptionForm.controls.subscriptionStartTime.value
      ),
      subscriptionEndTime: this.formatDate(
        this.subscriptionForm.controls.subscriptionEndTime.value
      ),
      productId: this.subscriptionForm.controls.productCode.value,
      isSubscribeNow,
      roleCode: this.subscriptionForm.controls.subscriptionType.value,
    }
    return data
  }

  formatDate(date) {
    const momentDate = moment(new Date(date))
    const formattedDate = momentDate.format('MM/DD/YYYY')
    return formattedDate
  }

  isToday(date): boolean {
    const today = new Date()
    return (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    )
  }

  onSave() {
    this.subscriptionDuplicate = false
    const data = this.prepareData()
    // debugger;
    if (this.subscriptionForm.invalid) {
      Object.keys(this.subscriptionForm.controls).forEach((controlName) =>
        this.subscriptionForm.controls[controlName].markAsTouched()
      )
      return
    }
    if (this.subscription === null) {
      // create a new subscription.
      this.subscriptionService
        .isSubscriptionValid(data)
        .subscribe((message) => {
          if (message === 'true') {
            this.subscriptionService.createSubscription(data).subscribe()
            setTimeout(() => {
              this.dialogRef.close()
            }, 500)
          } else {
            this.subscriptionDuplicate = true
          }
        })
    }
  }

  loadSubscriptionTypes() {
    this.subscriptionForm.controls.subscriptionType.setValue('')
    const currentProductCode: number = this.subscriptionForm.controls
      .productCode.value
    console.log('current selection is', currentProductCode)
    if (currentProductCode) {
      this.subscriptionService
        .loadAllSubscriptionTypes(currentProductCode)
        .subscribe((res) => {
          if (res) {
            this.subTypeList = res.data
          }
          this.subscriptionForm.controls.subscriptionType.enable()
        })
    }
  }

  calculateMinEndDate() {
    const date = this.subscriptionForm.controls.subscriptionStartTime.value
    if (!date) {
      return
    }

    const momentDate = moment(new Date(date))
    this.minEndDate = new Date(momentDate.add(1, 'd').format('MM/DD/YYYY'))
  }
}
