// NGRX
import { Action } from '@ngrx/store';
// CRUD
import { QueryParamsModel } from '../../_base/crud';
// Models
import { SubscriptionModel } from '../_models/subscription.model';

export enum SubscriptionActionTypes {
  SubscriptionOnServerCreated = '[Edit Subscription Dialog] Subscription On Server Created',
  SubscriptionPageLoaded = '[Subscription API] Subscription Page Loaded',
  SubscriptionPageToggleLoading = '[Subscription] Subscription Page Toggle Loading',
  SubscriptionActionToggleLoading = '[Subscription] Subscription Action Toggle Loading',
  SubscriptionPageRequested = '[Subscription] Subscription Page Requested',
  SubscriptionCreated = '[Subscription] Subscription Created',
}

export class SubscriptionOnServerCreated implements Action {
  readonly type = SubscriptionActionTypes.SubscriptionOnServerCreated;
  constructor(public payload: { subscription: SubscriptionModel }) {}
}

export class SubscriptionPageRequested implements Action {
  readonly type = SubscriptionActionTypes.SubscriptionPageRequested;
  constructor(public payload: { page: QueryParamsModel }) {}
}

export class SubscriptionPageLoaded implements Action {
  readonly type = SubscriptionActionTypes.SubscriptionPageLoaded;
  constructor(public payload: { subscription: SubscriptionModel[]; totalCount: number; page: QueryParamsModel }) {}
}

export class SubscriptionPageToggleLoading implements Action {
  readonly type = SubscriptionActionTypes.SubscriptionPageToggleLoading;
  constructor(public payload: { isLoading: boolean }) {}
}

export class SubscriptionActionToggleLoading implements Action {
  readonly type = SubscriptionActionTypes.SubscriptionActionToggleLoading;
  constructor(public payload: { isLoading: boolean }) {}
}
export class SubscriptionCreated implements Action {
  readonly type = SubscriptionActionTypes.SubscriptionCreated;
  constructor(public payload: { isLoading: boolean }) {}
}
export type SubscriptionActions =
  | SubscriptionOnServerCreated
  | SubscriptionCreated
  | SubscriptionPageRequested
  | SubscriptionPageLoaded
  | SubscriptionPageToggleLoading
  | SubscriptionActionToggleLoading;
