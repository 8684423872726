import { BaseModel } from '../../_base/crud';
import { ClientModel } from '..';

export class SubscriptionModel {
  id: number;
  clientId: number;
  companyId: string;
  companyName: string;
  companyType: string;
  userId: number;
  loginId: string;
  password: string;
  userEmailAddress: string;
  userType: string;
  userStatus: string;
  userFirstName: string;
  userLastName: string;
  userLanguage: string;
  roleCode: string;

  subscriptionId: number;
  subscriptionNumberOfUsers: number;
  subscriptionStartTime: string;
  subscriptionEndTime: string;
  subscriptionStatus: string;

  saasModuleId: number; // Product Table ID
  productCode: string;
  productName: string;
  productDescription: string;
  productAvailableForSubscription: number;

  constructor() {
    this.clearParent();
    this.subscriptionId = 0;
    this.subscriptionNumberOfUsers = 1;
    this.subscriptionStartTime = '0000/00/00';
    this.subscriptionEndTime = '0000/00/00';
    this.subscriptionStatus = 'ACTIVE';
    this.saasModuleId = 0;
    this.productCode = '';
    this.productName = '';
    this.productDescription = '';
    this.productAvailableForSubscription = 1;
  }

  clearParent() {
    this.id = 0;
    this.clientId = 0;
    this.companyId = '';
    this.companyName = '';
    this.companyType = 'GENERAL_CLIENT';
    this.userId = 0;
    this.loginId = '';
    this.password = '';
    this.userEmailAddress = '';
    this.userType = 'COMPANY_ADMIN';
    this.userStatus = 'ACTIVE';
    this.userFirstName = '';
    this.userLastName = '';
    this.userLanguage = 'EN';
  }

  clear() {
    this.clearParent();
    this.subscriptionId = 0;
    this.subscriptionNumberOfUsers = 1;
    this.subscriptionStartTime = '0000/00/00';
    this.subscriptionEndTime = '0000/00/00';
    this.subscriptionStatus = 'ACTIVE';
    this.saasModuleId = 0;
    this.productCode = '';
    this.productName = '';
    this.productDescription = '';
    this.productAvailableForSubscription = 1;
  }
}
