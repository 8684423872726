// Foundation
import { Component, OnInit, ElementRef, ViewChild, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { MatPaginator, MatSort, MatSnackBar, MatSnackBarConfig, MatDialog } from '@angular/material';

// RxJs
import { debounceTime, distinctUntilChanged, tap, skip, delay, take } from 'rxjs/operators';
import { fromEvent, merge, Subscription, of } from 'rxjs';

// Service
import { SubscriptionsDataSource } from '../../../../../../core/saas/_data-sources/subscriptions.datasource';
import { SubscriptionModel } from '../../../../../../core/saas/_models/subscription.model';
import { SubscriptionPageRequested } from '../../../../../../core/saas/_actions/subscription.actions';
import { TranslateService } from '@ngx-translate/core';
import { ConfigService, SNACKBAR } from '../../../../../../../app/config.service';
import { SubscriptionService } from '../../../../../../core/saas';

// NGRX
import { Store } from '@ngrx/store';
import { AppState } from '../../../../../../core/reducers';

// Model
import { QueryParamsModel } from '../../../../../../core/_base/crud';
import { SubscriptionsEditDialogComponent } from '../subscriptions-edit/subscriptions-edit.dialog.component';
import { SubscriptionsAddDialogComponent } from '../subscriptions-add/subscriptions-add.dialog.component';
import { SubscriptionsHistoryDialogComponent } from '../subscriptions-history/subscriptions-history.dialog.component';
import moment from 'moment';

@Component({
  selector: 'kt-subscriptions-list',
  templateUrl: './subscriptions-list.component.html',
  styleUrls: ['./subscriptions-list.component.scss'],
})
export class SubscriptionsListComponent implements OnInit, OnDestroy {
  // Table fields
  dataSource: SubscriptionsDataSource;
  displayedColumns = ['companyName', 'productCode', 'subscriptionStartTime', 'period',
                      'subscriptionEndTime', 'subscriptionStatus', 'actions'];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('sort1', { static: true }) sort: MatSort;
  // Filter fields
  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;
  filterStatus = '';
  filterProduct = '';
  // Selection
  selection = new SelectionModel<SubscriptionModel>(true, []);
  usersResult: SubscriptionModel[] = [];
  // Subscriptions
  private subscriptions: Subscription[] = [];

  private store2: Store<any>;
  isQuantumAdmin: boolean;

  constructor(
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private store: Store<AppState>,
    private storeParam: Store<any>,
    private translate: TranslateService,
    private configService: ConfigService,
    private subscriptionService: SubscriptionService,
  ) {
    this.store2 = storeParam;
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach((el) => el.unsubscribe());
  }

  ngOnInit() {
    this.isQuantumAdmin = false;
    this.store2.subscribe((state) => {
      if (state.auth.user.companyType === 'QUANTUM_ADMIN') {
        this.isQuantumAdmin = true;
      }
    });
    // If the user changes the sort order, reset back to the first page.
    const sortSubscription = this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
    this.subscriptions.push(sortSubscription);

    /* Data load will be triggered in two cases:
    - when a pagination event occurs => this.paginator.page
    - when a sort event occurs => this.sort.sortChange
    **/
    const paginatorSubscriptions = merge(this.sort.sortChange, this.paginator.page)
      .pipe(tap(() => this.loadSubscriptionsList()))
      .subscribe();
    this.subscriptions.push(paginatorSubscriptions);

    // Filtration, bind to searchInput
    const searchSubscription = fromEvent(this.searchInput.nativeElement, 'keyup')
      .pipe(
        // tslint:disable-next-line:max-line-length
        debounceTime(50), // The user can type quite quickly in the input box, and that could trigger a lot of server requests. With this operator, we are limiting the amount of server requests emitted to a maximum of one every 150ms
        distinctUntilChanged(), // This operator will eliminate duplicate values
        tap(() => {
          this.paginator.pageIndex = 0;
          this.loadSubscriptionsList();
        })
      )
      .subscribe();
    this.subscriptions.push(searchSubscription);

    // Init DataSource
    this.dataSource = new SubscriptionsDataSource(this.store);
    const compareFunc = (pre, curr) => JSON.stringify(pre) === JSON.stringify(curr);
    // don't run if the stringified version of the object is the same.
    const entitiesSubscription = this.dataSource.entitySubject.pipe(skip(1), distinctUntilChanged(compareFunc)).subscribe((res) => {
      this.usersResult = res;
    });
    this.subscriptions.push(entitiesSubscription);
    // First load
    of(undefined)
      .pipe(take(1), delay(1000))
      .subscribe(() => {
        // Remove this line, just loading imitation
        this.loadSubscriptionsList();
      }); // Remove this line, just loading imitation
  }

  loadSubscriptionsList() {
    this.selection.clear();
    const queryParams = new QueryParamsModel(
      this.filterConfiguration(),
      this.sort.direction,
      this.sort.active,
      this.paginator.pageIndex,
      this.paginator.pageSize
    );
    // Call request from server
    // this.store.dispatch(new ClientsPageRequested({ page: queryParams }));
    this.store.dispatch(new SubscriptionPageRequested({ page: queryParams }));
    this.selection.clear();
  }

  filterConfiguration(): any {
    const filter: any = {};
    const searchText: string = this.searchInput.nativeElement.value;
    console.log('this.filterStatus: ', this.filterStatus);
    console.log('this.filterProduct: ', this.filterProduct);

    if (this.filterStatus && this.filterStatus.length > 0) {
      filter.subscriptionStatus = this.filterStatus;
    }

    if (this.filterProduct && this.filterProduct.length > 0) {
      filter.productCode = this.filterProduct;
    }

    filter.companyName = searchText;
    return filter;
  }

  getItemCssClassByStatus(status: string): string {
    switch (status) {
      case 'ACTIVE':
        return 'success';
      case 'PENDING':
        return 'metal';
    }
    return '';
  }

  addSubscription() {
    const dialogRef = this.dialog.open(SubscriptionsAddDialogComponent, { data: null });
    dialogRef.afterClosed().subscribe(() => {
      this.loadSubscriptionsList();
    });
  }

  activateSubscription(subscription) {
    const dialogRef = this.dialog.open(SubscriptionsEditDialogComponent, { data: subscription });
    dialogRef.afterClosed().subscribe(() => {
      this.loadSubscriptionsList();
    });
  }

  openSubscriptionHistory(subscription) {
    const dialogRef = this.dialog.open(SubscriptionsHistoryDialogComponent, { data: subscription });
    dialogRef.afterClosed().subscribe(() => {
      this.loadSubscriptionsList();
    });
  }

  syncSubscription(clientId: number, productId: number) {
    // do nothing if either clientId or productId is missing
    if (!clientId || !productId) {
      this.showSnackBarAfterSync(false);
      return;
    }

    this.subscriptionService.syncSubscription(clientId, productId).subscribe(message => {
      let isSyncSuccess = true;
      if (message === 'SUCCESS') {
        this.showSnackBarAfterSync(isSyncSuccess);
      } else {
        isSyncSuccess = false;
        this.showSnackBarAfterSync(isSyncSuccess);
      }
    });
  }

  showSnackBarAfterSync(isSyncSuccess: boolean) {
    if (isSyncSuccess) {
      const message = this.configService.translateSnackBarMessage(SNACKBAR.SYNC_SUCCESS);
      const action = this.configService.translateSnackbarAction(SNACKBAR.ACTION_CLOSE);

      const config: MatSnackBarConfig = { duration: 3000, panelClass: ['saas-snackbar-style-success'] };
      this.snackBar.open(message, action, config);
    } else if (!isSyncSuccess) {
      const message = this.configService.translateSnackBarMessage(SNACKBAR.SYNC_FAILED);
      const action = this.configService.translateSnackbarAction(SNACKBAR.ACTION_CLOSE);

      const config: MatSnackBarConfig = { duration: 3000, panelClass: ['saas-snackbar-style-fail'] };
      this.snackBar.open(message, action, config);
    }
  }

  calculatePeriod(startDate: string, endDate: string): number {
    const firstDate = moment(startDate);
    const secondDate = moment(endDate);
    return Math.abs(firstDate.diff(secondDate, 'days'));
  }

  translateProduct(productCode: string): string {
    const res = this.configService.translateProductName(productCode);
    return res;
  }
}
