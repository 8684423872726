import { Action } from '@ngrx/store';
import { User } from '../_models/user.model';

export enum AuthActionTypes {
    Login = '[Login] Action11',
    LoginSuccess = '[Login Success] Action11',
    // Logout = '[Logout] Action',
    // Register = '[Register] Action',
    // UserRequested = '[Request User] Action',
    // UserLoaded = '[Load User] Auth API'
}

export class Login implements Action {
    readonly type = AuthActionTypes.Login;
    constructor(public payload: { 
        email: string,
        password: string
    }) {}
}

export class LoginSuccess implements Action {
    readonly type = AuthActionTypes.LoginSuccess;
  
    constructor(public payload: User) {}
  }

// export class Logout implements Action {
//     readonly type = AuthActionTypes.Logout;
// }

// export class Register implements Action {
//     readonly type = AuthActionTypes.Register;
//     constructor(public payload: { authToken: string }) { }
// }


// export class UserRequested implements Action {
//     readonly type = AuthActionTypes.UserRequested;
// }

// export class UserLoaded implements Action {
//     readonly type = AuthActionTypes.UserLoaded;
//     constructor(public payload: { user: User }) { }
// }



// export type AuthActions = Login | Logout | Register | UserRequested | UserLoaded;

export type AuthActions = Login | LoginSuccess;
