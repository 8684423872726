// NGRX
import { createFeatureSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter, Update } from '@ngrx/entity';
// Actions
import { CompanyUserActions, CompanyUserActionTypes } from '../_actions/companyUser.actions';
// Models
import { CompanyUserModel } from '../_models/companyUser.model';
import { QueryParamsModel } from '../../_base/crud';

export interface CompanyUsersState extends EntityState<CompanyUserModel> {
  listLoading: boolean;
  actionsloading: boolean;
  totalCount: number;
  lastCreatedcompanyUserId: number;
  lastQuery: QueryParamsModel;
  showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<CompanyUserModel> = createEntityAdapter<CompanyUserModel>();

export const initialcompanyUsersState: CompanyUsersState = adapter.getInitialState({
  companyUserForEdit: null,
  listLoading: false,
  actionsloading: false,
  totalCount: 0,
  lastCreatedcompanyUserId: undefined,
  lastQuery: new QueryParamsModel({}),
  showInitWaitingMessage: true,
});

export function companyUsersReducer(state = initialcompanyUsersState, action: CompanyUserActions): CompanyUsersState {
  switch (action.type) {
    case CompanyUserActionTypes.CompanyUsersPageToggleLoading: {
      return {
        ...state,
        listLoading: action.payload.isLoading,
        lastCreatedcompanyUserId: undefined,
      };
    }
    case CompanyUserActionTypes.CompanyUserActionToggleLoading: {
      return {
        ...state,
        actionsloading: action.payload.isLoading,
      };
    }
    case CompanyUserActionTypes.CompanyUserOnServerCreated:
      return {
        ...state,
      };
    //   case CompanyUserActionTypes.CompanyUserCreated:
    //     return adapter.addOne(action.payload.companyUser, {
    //       ...state,
    //       lastCreatedcompanyUserId: action.payload.companyUser.id,
    //     });
    //   case CompanyUserActionTypes.companyUsersStatusUpdated: {
    //     const _partialcompanyUsers: Update<CompanyUserModel>[] = [];
    //     // tslint:disable-next-line:prefer-const
    //     for (let i = 0; i < action.payload.companyUsers.length; i++) {
    //       _partialcompanyUsers.push({
    //         id: action.payload.companyUsers[i].id,
    //         changes: {
    //           status: action.payload.status,
    //         },
    //       });
    //     }
    //     return adapter.updateMany(_partialcompanyUsers, state);
    //   }
    case CompanyUserActionTypes.CompanyUsersPageLoaded: {
      return adapter.addMany(action.payload.companyUsers, {
        ...initialcompanyUsersState,
        totalCount: action.payload.totalCount,
        listLoading: false,
        lastQuery: action.payload.page,
        showInitWaitingMessage: false,
      });
    }
    default:
      return state;
  }
}

export const getcompanyUserState = createFeatureSelector<CompanyUserModel>('companyUsers');

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
