export enum Products {
  TRA = 'TRA',
  RISK = 'RISK',
  EMER = 'EMER',
  INCI = 'INCI',
  INSP = 'INSP',
  BBSO = 'BBSO',
  REG = 'REG',
  DOC = 'DOC',
  CON = 'CON',
  HAZ = 'HAZ',
  NOT = 'NOT'
}
