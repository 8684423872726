export class ClientsTable {
	public static clients: any = [
		{
			id: 1,
			companyName: 'Logic Solutions, LLC',
			companyId: 'orgxxxx',
			adminUser: 'sara.sha@usequantum.com',
			product: 0,
			status: 1
			
		},
		{
			id: 2,
			companyName: '上海奥星制药技术装备有限公司',
			companyId: '',
			adminUser: '',
			product: 1,
			status: 2
		},
	];
}
