// Foundation
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// RxJs
import { finalize, takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';

// Service
import { TranslateService } from '@ngx-translate/core';
import { CommunicationService } from './../../../../../../src/app/core/auth/_services/communication.service';
import { ConfigService } from '../../../../../app/config.service';
import { AuthNoticeService, AuthService } from '../../../../core/auth/';
// NGRX
import { Store } from '@ngrx/store';
import { AppState } from '../../../../core/reducers';

// Translate
import { TranslationService } from '../../../../core/_base/layout';

@Component({
  selector: 'activate-account',
  templateUrl: './activate-account.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class ActivateAccountComponent implements OnInit, OnDestroy {
  resetForm: FormGroup;
  loading = false;
  errors: any = [];
  @Output() public childEvent = new EventEmitter();
  public userId: number;
  public companyId: string;
  public isCompanyAdmin: number;
  public productType: string;
  public emailAddress: string;
  public userLanguage: string;
  private unsubscribe: Subject<any>;
  showPwd: boolean = false;

  constructor(
    private authNoticeService: AuthNoticeService,
    private translate: TranslateService,
    private authService: AuthService,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private communicationService: CommunicationService,
    private activatedRoute: ActivatedRoute,
    private configService: ConfigService,
    private translationService: TranslationService,
    private router: Router,
  ) {
    this.unsubscribe = new Subject();
  }

  ngOnInit() {
    this.userId = parseInt(this.activatedRoute.snapshot.paramMap.get('userId'), 10);
    this.companyId = this.activatedRoute.snapshot.paramMap.get('companyId');
    this.isCompanyAdmin = parseInt(this.activatedRoute.snapshot.paramMap.get('isCompanyAdmin'), 10);
    this.productType = this.activatedRoute.snapshot.paramMap.get('productType');
    this.emailAddress = this.activatedRoute.snapshot.paramMap.get('emailAddress');
    this.userLanguage = this.activatedRoute.snapshot.paramMap.get('userLanguage');
    this.initResetForm();
    this.translationService.setLanguage(this.userLanguage);
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
    this.loading = false;
  }

  initResetForm() {
    this.resetForm = this.fb.group(
      {
        password: ['', Validators.required],
      },
    );
  }

  /**
   * Form Submit
   */
  submit() {
    const controls = this.resetForm.controls;
    /** check form */
    if (this.resetForm.invalid) {
      Object.keys(controls).forEach((controlName) => controls[controlName].markAsTouched());
      return;
    }

    this.loading = true;
    const password = controls.password.value;
    if (this.userId == null) {
      return;
    }
    let isCompanyAdminBoolean: boolean;
    if (this.isCompanyAdmin === 1) {
      isCompanyAdminBoolean = true;
    } else {
      isCompanyAdminBoolean = false;
    }
    this.authService
      .activateAccount(this.emailAddress, password)
      .pipe(
        tap((response) => {
          if (response === "SUCCESS") {
            this.authNoticeService.setNotice(this.translate.instant('AUTH.FORGOT.SUCCESS'), 'success');
            localStorage.setItem("companyId", this.companyId);
            this.router.navigateByUrl('/auth/activated');
          } else {
            this.authNoticeService.setNotice(
              this.translate.instant('AUTH.VALIDATION.NOT_FOUND', { name: this.translate.instant('AUTH.INPUT.EMAIL') }),
              'danger'
            );
          }
        }),
        takeUntil(this.unsubscribe),
        finalize(() => {
          this.loading = false;
          this.cdr.markForCheck();
        })
      )
      .subscribe();
  }

  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.resetForm.controls[controlName];
    if (!control) {
      return false;
    }

    const result = control.hasError(validationType) && (control.dirty || control.touched);
    return result;
  }
}
