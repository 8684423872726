// Foundation
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

// Service
import { CompanyUsersService } from '../../../../../../core/saas';

// NGRX
import { Store } from '@ngrx/store';
import { AppState } from '../../../../../../core/reducers';

// Model
import { CompanyUserModel } from '../../../../../../core/saas';

@Component({
  selector: 'kt-user-confirmation-dialog',
  templateUrl: './user-confirmation-dialog.component.html',
})
export class UserConfirmationDialogComponent implements OnInit {
  viewLoading = false;
  editedCompanyUser: CompanyUserModel;
  companyUsersService: CompanyUsersService;
  dialogContent: string;

  constructor(
    public dialogRef: MatDialogRef<UserConfirmationDialogComponent>,
    private store: Store<AppState>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    companyUsersService: CompanyUsersService
  ) {
    this.companyUsersService = companyUsersService;
  }

  // Life Cycles
  // ------------------------------------------------------------------------------------

  ngOnInit() {
    this.editedCompanyUser = this.data.companyUser;
    this.dialogContent = this.data.dialogContent;
  }

  onNoClick(): void {
    this.dialogRef.close('no');
  }

  onYesClick(): void {
    this.dialogRef.close('yes');
  }
}
