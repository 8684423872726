// NGRX
import { createFeatureSelector, createSelector } from '@ngrx/store';
// Lodash
import { each } from 'lodash';
// CRUD
import { QueryResultsModel, HttpExtenstionsModel } from '../../_base/crud';
// State
import { SubscriptionsState } from '../_reducers/subscription.reducers';
import { SubscriptionModel } from '../_models/subscription.model';

export const selectSubscriptionsState = createFeatureSelector<SubscriptionsState>('subscriptions');

export const selectSubscriptionById = (subscriptionId: number) =>
  createSelector(selectSubscriptionsState, (subscriptionsState) => subscriptionsState.entities[subscriptionId]);

export const selectSubscriptionsPageLoading = createSelector(
  selectSubscriptionsState,
  (subscriptionsState) => subscriptionsState.listLoading
);

export const selectSubscriptionsActionLoading = createSelector(
  selectSubscriptionsState,
  (subscriptionsState) => subscriptionsState.actionsloading
);

export const selectLastCreatedSubscriptionId = createSelector(
  selectSubscriptionsState,
  (subscriptionsState) => subscriptionsState.lastCreatedsubscriptionId
);

export const selectSubscriptionsShowInitWaitingMessage = createSelector(
  selectSubscriptionsState,
  (subscriptionsState) => subscriptionsState.showInitWaitingMessage
);

export const selectSubscriptionsInStore = createSelector(selectSubscriptionsState, (subscriptionsState) => {
  const items: SubscriptionModel[] = [];
  each(subscriptionsState.entities, (element) => {
    items.push(element);
  });
  const httpExtension = new HttpExtenstionsModel();
  const result: SubscriptionModel[] = httpExtension.sortArray(
    items,
    subscriptionsState.lastQuery.sortField,
    subscriptionsState.lastQuery.sortOrder
  );
  return new QueryResultsModel(result, subscriptionsState.totalCount, '');
});
