// Angular
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

// Translate Module
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'kt-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
})
export class ConfirmationDialogComponent implements OnInit {
  viewLoading = false;
  title: string;
  content: string;
  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    //debugger;
    this.title = '';
    this.content = '';
    if (this.data.titleTranslationCode !== undefined && this.data.titleTranslationCode !== '') {
      this.title = this.translate.instant(this.data.titleTranslationCode);
    }

    if (this.data.contentTranslationCode !== undefined && this.data.contentTranslationCode !== '') {
      this.content = this.translate.instant(this.data.contentTranslationCode);
    }
  }

  onNoClick(): void {
    this.dialogRef.close('no');
  }

  onYesClick(): void {
    this.dialogRef.close('yes');
  }
}
