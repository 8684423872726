// Angular
import { Component, Input, OnInit } from '@angular/core';

// NGRX
import { Store } from '@ngrx/store';

// Service
import { Logout } from '../../../../../core/auth';

// Model
import { User } from '../../../../../core/saas/_models/user.model';

@Component({
  selector: 'kt-user-profile',
  templateUrl: './user-profile.component.html',
})
export class UserProfileComponent implements OnInit {
  // Public properties
  user: User;

  @Input() avatar = true;
  @Input() greeting = true;
  @Input() badge: boolean;
  @Input() icon: boolean;

  constructor(private store: Store<any>) {}

  // Life Cycles
  // ------------------------------------------------------------------------------------

  ngOnInit(): void {
    this.store.subscribe((state) => {
      this.user = state.auth.user;
    });
  }

  logout() {
    this.store.dispatch(new Logout());
  }
}
