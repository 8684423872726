// Foundation
import { Component, OnDestroy, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// RxJs
import { Subject } from 'rxjs';

// Service
import { TranslateService } from '@ngx-translate/core';
import { CommunicationService } from './../../../../../../src/app/core/auth/_services/communication.service';
import { AuthNoticeService } from '../../../../core/auth/';
import { ClientsService } from '../../../../core/saas/_services/clients.service.fake';

// Model
import { ClientModel } from '../../../../core/saas/_models/client.model';

// NGRX
import { Store } from '@ngrx/store';
import { AppState } from '../../../../core/reducers';
import { ClientOnServerCreated } from '../../../../core/saas/_actions/client.actions';

// Translate
import { TranslationService } from '../../../../core/_base/layout';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'kt-free-trial',
  templateUrl: './free-trial.component.html',
  styleUrls: ['./free-trial.component.scss'],
})
export class FreeTrialComponent implements OnInit, OnDestroy {
  trialSignUpForm: FormGroup;
  loading = false;
  errors: any = [];
  @Output() public childEvent = new EventEmitter();
  @ViewChild('submit', { static: true }) submit;
  public userId: number;
  public companyId: string;
  public isCompanyAdmin: number;
  public emailAddress: string;
  public language: string; // en/ch/tr
  public product: string; // Safety_Observation, Chemical_Management, COVID_Check
  isTermsAndConditionsChecked = false;
  showMustAcceptMessage = false;
  private unsubscribe: Subject<any>;
  constructor(
    private authNoticeService: AuthNoticeService,
    private translate: TranslateService,
    private fb: FormBuilder,
    private communicationService: CommunicationService,
    private activatedRoute: ActivatedRoute,
    private translationService: TranslationService,
    private clientsService: ClientsService,
    private store: Store<AppState>
  ) {
    this.unsubscribe = new Subject();
  }

  ngOnInit() {
    this.communicationService.emitChange('SaaS Management Platform');
    this.language = this.activatedRoute.snapshot.paramMap.get('language');
    this.product = this.activatedRoute.snapshot.paramMap.get('product');
    console.log(this.product);
    this.translationService.setLanguage(this.language);
    this.initTrialSignUpForm();
    this.trialSignUpForm.get('product').setValue(this.product);
    this.trialSignUpForm.get('product').disable();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
    this.loading = false;
  }

  initTrialSignUpForm() {
    this.trialSignUpForm = this.fb.group({
      company_name: ['', Validators.required],
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      email: ['', Validators.compose([Validators.required, Validators.email])],
      phone: ['', Validators.required],
      job_title: [''],
      product: ['', Validators.required],
    });
  }

  onClickSubmit() {
    const controls = this.trialSignUpForm.controls;
    if (this.trialSignUpForm.invalid) {
      Object.keys(controls).forEach((controlName) => controls[controlName].markAsTouched());
      return;
    }
    if (!this.isTermsAndConditionsChecked) {
      this.showMustAcceptMessage = true;
      return;
    } else {
      this.showMustAcceptMessage = false;
    }
    this.submit.nativeElement.disabled = true;
    const client: ClientModel = this.prepareClient();
    this.createClient(client);
  }

  onClickCancel() {
    window.location.href = this.getCancelBtnUrl();
  }

  private convertLanguageToCode(language: string): string {
    let result;
    switch (language) {
      case 'en':
        result = 'EN';
        break;
      case 'ch':
        result = 'CN';
        break;
      case 'tr':
        result = 'TW';
        break;
      default:
        break;
    }
    return result;
  }

  private prepareClient(): ClientModel {
    const controls = this.trialSignUpForm.controls;
    const client = new ClientModel();
    client.id = 0;
    client.userType = 'COMPANY_ADMIN';
    client.companyType = 'TRIAL_CLIENT';
    client.companyId = 'TRIAL';
    client.loginId = controls.email.value;
    client.userEmailAddress = controls.email.value;
    client.companyName = controls.company_name.value;
    client.userStatus = 'PENDING';
    client.userLanguage = this.convertLanguageToCode(this.language);
    client.userFirstName = controls.first_name.value;
    client.userLastName = controls.last_name.value;
    client.userPhone = controls.phone.value;
    client.userJobTitle = controls.job_title.value;
    return client;
  }

  private createClient(client: ClientModel) {
    // this.clientsService
    //   .isCompanyIdDuplicate(client.companyId, client.clientId)
    //   .pipe(
    //     map((data) => {
    //       if (data === 'true') {
    //         // duplicate company id
    //         console.log('duplicate');
    //       } else {
    //         this.store.dispatch(new ClientOnServerCreated({ client }));
    //         this.authNoticeService.setNotice(this.translate.instant('TRIAL_SIGNUP.SUCCESS'), 'success');

    //         // setTimeout(() => {
    //         //   window.location.href = this.configService.SAAS_DOMAIN;
    //         // }, 3000);
    //       }
    //     })
    //   )
    //   .subscribe();
    const productId = this.convertProductCodeToId(this.trialSignUpForm.controls.product.value);
    console.log(productId);
    console.log(client);
    this.clientsService.trialSubscribe(client, productId).subscribe((data) => {
      if (data === 'SUCCESS') {
        this.authNoticeService.setNotice(this.translate.instant('TRIAL_SIGNUP.SUCCESS'), 'success');
      }
    });
  }

  convertProductCodeToId(product) {
    let result = 0;
    switch (product) {
      case 'Safety_Observation':
        result = 1;
        break;
      case 'Chemical_Management':
        result = 2;
        break;
      case 'COVID_Check':
        result = 3;
        break;
      default:
        break;
    }
    return result;
  }

  test() {
    console.log('isTermsAndConditionsChecked', this.isTermsAndConditionsChecked);
  }

  getCancelBtnUrl(): string {
    // SAAS_DOMAIN = environment.saasDomain;
    switch (this.product) {
      case 'Safety_Observation':
        if (this.language === 'en') {
          return environment.trialCancelButtonUrlSafetyObservation_English;
        } else if (this.language === 'ch') {
          return environment.trialCancelButtonUrlSafetyObservation_SimplifiedChinese;
        } else if (this.language === 'tr') {
          return environment.trialCancelButtonUrlSafetyObservation_TraditionalChinese;
        }
        break;
      case 'Chemical_Management':
        if (this.language === 'en') {
          return environment.trialCancelButtonUrlSDS_English;
        } else if (this.language === 'ch') {
          return environment.trialCancelButtonUrlSDS_SimplifiedChinese;
        } else if (this.language === 'tr') {
          return environment.trialCancelButtonUrlSDS_TraditionalChinese;
        }
        break;
      case 'COVID_Check':
        if (this.language === 'en') {
          return environment.trialCancelButtonUrlCovidCheck_English;
        } else if (this.language === 'ch') {
          return environment.trialCancelButtonUrlCovidCheck_SimplifiedChinese;
        } else if (this.language === 'tr') {
          return environment.trialCancelButtonUrlCovidCheck_TraditionalChinese;
        }
        break;
      default:
        return '';
        break;
    }
  }
}
