// Taiwan
export const locale = {
  lang: 'tr',
  data: {
    PAGINATOR: {
      ITEMS_PER_PAGE: '每頁顯示筆數',
      OF: '總筆數',
    },
    TRANSLATOR: {
      SELECT: '選擇你的語言',
    },
    BUTTON: {
      CANCEL: '取消',
      CONFIRM: '確認',
      SAVE: '保存',
      ACTIVATE: '啟用',
      DEACTIVATE: '註銷',
    },
    LANGUAGE: {
      ENGLISH: '英語',
      SIMPLIFIED_CHINESE: '簡體中文',
      TRADITIONAL_CHINESE: 'Traditional Chinese',
    },
    PRODUCT: {
      Chemical_Management: '化學品管理',
      SAFETY_OBSERVATION: '安全觀察',
      COVID_CHECK: 'COVID Check',
      REGULATION: '法規鑑別',
      TRAINING: '培訓與證照',
      RISK: '風險評估',
      INCIDENT: '事故管理',
    },
    ERROR: {
      FORM_FIELD_INVALID_ALERT: '請檢查各欄位是否正確',
      IS_REQUIRED: '為空或格式不正確',
      COMPANY_ID_DUPLICATE: '公司ID已存在',
    },
    SEARCH: {
      ALL_SEARCH: '全部搜尋',
      ALL: '所有',
      SUSPEND: '暫停',
      ACTIVE: '已啟用',
      PENDING: '待定',
      INACTIVE: '已注銷',
      SEARCH: '搜索',
      FILTER_BY_STATUS: '以狀態篩選',
      FILTER_BY_TYPE: '以類別篩選',
      FILTER_BY_PRODUCT: '以產品篩選',
      SEARCH_IN_ALL_FIELD: '在所有欄位篩選',
    },
    MENU: {
      NEW: '新增',
      ACTIONS: '功能',
      CREATE_POST: '創建新文章',
      PAGES: '頁面',
      FEATURES: '功能',
      APPS: '應用',
      DASHBOARD: '儀表板',
      LAYOUTBUILDER: '界面',
      CLIENTS: '客戶管理',
      USERS: '使用者管理',
      SUBSCRIPTIONS: '訂閱',
    },
    AUTH: {
      GENERAL: {
        OR: '或者',
        SUBMIT_BUTTON: '送出',
        NO_ACCOUNT: '沒有賬號？',
        SIGNUP_BUTTON: '註冊',
        FORGOT_BUTTON: '忘記密碼',
        BACK_BUTTON: '退回',
        PRIVACY: '隱私',
        LEGAL: '法律',
        CONTACT: '聯系',
      },
      LOGIN: {
        TITLE: '登入帳號',
        BUTTON: '登入',
      },
      FORGOT: {
        TITLE: '忘記密碼?',
        DESC: '請輸入您的電子郵件地址重置密碼。',
        SUCCESS: '已成功儲存您的密碼，正在匯入您的產品...',
      },
      REGISTER: {
        TITLE: '註冊',
        DESC: 'Enter your details to create your account',
        SUCCESS: 'Your account has been successfully registered.',
      },
      INPUT: {
        EMAIL: ' 電子郵件地址',
        FULLNAME: '全名',
        PASSWORD: '密碼',
        CONFIRM_PASSWORD: '再一次輸入密碼',
        USERNAME: '使用者名稱',
        IAGREE: '我已閱讀並同意',
        TERMS: '服務協議',
        AND: '和',
        CONDITIONS: '隱私聲明',
        NOACCOUNT: '還沒有帳號?',
      },
      RESET: {
        TITLE: '設置密碼',
        PASSWORD: '密碼',
        CONFIRM_PASSWORD: '再一次輸入密碼',
      },
      VALIDATION: {
        INVALID: '{{name}} is not valid',
        REQUIRED: '{{name}} is required',
        MIN_LENGTH: '{{name}} minimum length is {{min}}',
        AGREEMENT_REQUIRED: 'Accepting terms & conditions are required',
        NOT_FOUND: '{{name}}沒有找到',
        INVALID_LOGIN: '您的登入資料不正確，請檢查!',
        MAX_LENGTH_FIELD: 'Maximum field length:',
        INVALID_FIELD: 'Field is not valid',

        REQUIRED_FIELD: '不可為空',
        MIN_LENGTH_FIELD: '最短密碼長度:',
        PASSWORD_DOESNOT_MATCH: '兩次輸入的密碼不相符.',
      },
    },
    CLIENT: {
      TITLE: {
        NEW_CLIENT: '新建客戶',
        CLIENTS: '客戶管理',
      },
      PROFILE: {
        SIGNOUT: '登出',
      },
      TABLE: {
        COMPANY_NAME: '公司名稱',
        COMPANY_ID: '公司ID',
        LANGUAGE: '通知語言',
        ADMIN_USER: '管理員郵箱賬號',
        PRODUCT: '產品',
        STATUS: '狀態',
        ACTIONS: '操作',
        COMPANY_ADMIN: 'Admin',
        GENERAL_USER: 'General User',
        FIRST_NAME: '名',
        LAST_NAME: '姓',
        PHONE: '電話',
        JOB_TITLE: '職位',
      },
      BUTTON: {
        CANCEL: '取消',
        SAVE: '保存',
        ACTIVATE: '啟用',
        DEACTIVATE: '註銷',
      },

      CONFIRMATION: {
        ACTIVATE: '您確定要啟用此公司資料庫嗎?',
      },
    },
    COMPANY_USER: {
      DASHBOARD: {
        CHOOSE_PRODUCT: '選擇你的產品',
      },
      TABLE: {
        COMPANY: 'Company',
        LOGIN_EMAIL: '登入電子郵件',
        ROLE: 'Role',
        STATUS: '狀態',
        ACTIONS: '操作',
        LANGUAGE: 'Notification Language',
        FIRST_NAME: '名',
        LAST_NAME: '姓',
        FULL_NAME: 'Full Name',
        PHONE: '電話',
        JOB_TITLE: '職位',
        COMPANY_ADMIN: 'Admin',
        GENERAL_USER: 'General User',
      },
      TITLE: {
        NEW_USER: '新建使用者',
        USER_MANAGEMENT: '使用者管理',
      },
      BUTTON: {
        SAVE: 'Save',
      },
      ALERT: {
        CHANGE_ROLE: 'SUCCESS, logging out',
        CHANGE_ROLE_MESSAGE: 'Only one user will be company admin. Are you sure to continue?',
      },
    },
    SUBSCRIPTION: {
      SUBSCRIPTION: '訂閱',
      TABLE: {
        CLIENT: '客戶',
        PRODUCT: '產品',
        START_DATE: '開始時間',
        PERIOD: '時長',
        END_DATE: '結束時間',
        STATUS: '狀態',
        ADD_SUBSCRIPTION: '新增訂閱',
        CLIENT_INFO: '客戶資訊',
        SUBSCRIPTION_TYPE: '訂閱類型',
        SUBSCRIPTION_INFO: '訂閱資訊',
        SUBSCRIPTION_HISTORY: '歷史訂閱',
        RENEW_SUBSCRIPTION: '續訂',
        CANCEL_SUBSCRIPTION: '取消訂閱',
        DATE: '時間',
      },
      UNSUBSCRIBE: {
        TITLE: '取消訂閱',
        DESCRIPTION: '你確定要取消訂閱嗎?',
        CONFIRM_ACTION: '取消訂閱',
        CANCEL_ACTION: '不要取消',
        SUCCESS_MESSAGE: '取消訂閱成功',
        FAIL_MESSAGE: '取消訂閱失敗'
      },
      SYNC: {
        SUCCESS_MESSAGE: '同步成功 !',
        FAIL_MESSAGE: '同步失敗 !'
      },
      BUTTON: {
        ADD: '新增',
        CLOSE: '關閉'
      },
      ALERT: {
        SUBSCRIPTION_DUPLICATE: 'The client already has the subscription of product that covers the period',
      },
    },
    ECOMMERCE: {
      CUSTOMERS: {
        CUSTOMERS: '顧客',
        CUSTOMERS_LIST: '客戶名單',
        NEW_CUSTOMER: '新增客戶',
      },
    },
    REGISTER: {
      HI: '您好',
      MESSAGE: '距離產品僅一步之遙…',
      TITLE: '立刻啟用您的帳戶',
      PASSWORD: '密碼',
      CONFIRM_PASSWORD: '確認密碼',
      FIRST_NAME: '名',
      LAST_NAME: '姓',
      SUBMIT_BUTTON: '送出',
    },
    TRIAL_SIGNUP: {
      TITLE: '立即開始14天的免費體驗',
      COMPANY_NAME: '公司名稱',
      FIRST_NAME: '名',
      LAST_NAME: '姓',
      PHONE: '電話',
      JOB_TITLE: '職位',
      EMAIL: '電子郵件',
      PRODUCT: '产品',
      IAGREE: '我已閱讀並同意',
      TERMS_AND_CONDITIONS: '試用協議',
      MUSTACCEPT: '您必須同意試用協議',
      SUBMIT: '提交',
      CANCEL: '取消',
      SUCCESS: '您的信息已成功提交。我們會盡快與您聯繫，謝謝。',
    },
  },
};
