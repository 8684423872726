// Angular
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
// Core Module
import { CoreModule } from '../../../core/core.module';
import { PartialsModule } from '../../partials/partials.module';
import { DashboardCompanyAdminComponent } from './dashboard-company-admin.component';
import { DashboardDialogComponent } from './dashboard-dialog/dashboard-dialog.component';
import { MatDialogModule, MAT_DIALOG_DEFAULT_OPTIONS, MatButtonModule, MatPaginatorIntl } from '@angular/material';
import { PaginatorIntlService } from '../../../../app/core/saas/_services/paginatorIntl.service';
// Translate Module
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    PartialsModule,
    CoreModule,
    MatDialogModule,
    MatButtonModule,
    RouterModule.forChild([
      {
        path: '',
        component: DashboardCompanyAdminComponent,
      },
    ]),
    TranslateModule.forChild(),
  ],
  providers: [
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {
        hasBackdrop: true,
        panelClass: 'kt-mat-dialog-container__wrapper',
        height: 'auto',
        width: '900px',
      },
    },
  ],
  entryComponents: [DashboardDialogComponent],
  declarations: [DashboardCompanyAdminComponent, DashboardDialogComponent],
})
export class DashboardCompanyAdminModule {}
