import { BaseModel } from '../../_base/crud';

export class CompanyUserModel extends BaseModel {
  // id: number;
  // clientId: number;
  // userId: number;
  // subscriptionId: number;
  // subscriptionModuleIds: number[];
  // saasModuleIds: number[];
  // adminUser: string;
  // companyId: string;
  // companyName: string;
  // products: string[];
  // status: number; // 1 = Active | 2 = Pending | 3 = Inactive
  // language: number; //0 = English | 2 = Simplified Chinese | 3 = Traditional Chinese
  id: number;
  clientId: number;
  companyId: string;
  companyName: string;
  companyType: string;
  userId: number;
  loginId: string;
  password: string;
  userEmailAddress: string;
  userType: string;
  userStatus: string;
  userFirstName: string;
  userLastName: string;
  userPhone: string;
  userJobTitle: string;
  userLanguage: string;
  constructor() {
    super();
    this.id = 0;
    this.clientId = 0;
    this.companyId = '';
    this.companyName = '';
    this.companyType = 'GENERAL_CLIENT';
    this.userId = 0;
    this.loginId = '';
    this.password = '';
    this.userEmailAddress = '';
    this.userType = 'COMPANY_ADMIN';
    this.userStatus = 'PENDING';
    this.userFirstName = '';
    this.userLastName = '';
    this.userLanguage = 'EN';
    this.userPhone = '';
    this.userJobTitle = '';
  }
  clear() {
    this.id = 0;
    this.clientId = 0;
    this.companyId = '';
    this.companyName = '';
    this.companyType = 'GENERAL_CLIENT';
    this.userId = 0;
    this.loginId = '';
    this.password = '';
    this.userEmailAddress = '';
    this.userType = 'COMPANY_ADMIN';
    this.userStatus = 'PENDING';
    this.userFirstName = '';
    this.userLastName = '';
    this.userLanguage = 'EN';
    this.userPhone = '';
    this.userJobTitle = '';
  }

  // clear() {
  //   this.adminUser = '';
  //   this.companyId = '';
  //   this.companyName = '';
  //   this.subscriptionModuleIds = null;
  //   this.saasModuleIds = null;
  //   this.products = null;
  //   this.status = 2; // Set default status Pending
  //   this.language = 0; // Set default language to English
  // }
}
